import { useDispatch, useSelector } from 'react-redux';
import { motion } from "framer-motion";
import InputField from "components/fields/InputField";
import toast, { Toaster } from 'react-hot-toast';
import { useState, useEffect } from 'react';
import { checkEntity, loginMobile } from '../../../redux/actions/auth';
import { getOpenSetting } from '../../../redux/actions/admin/setting';
import { Link, useNavigate } from 'react-router-dom';
import Eye from "components/icons/Eye";

const containerVariants = {
  hidden: { 
    opacity: 0,
    y: 20
  },
  visible: { 
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: "easeOut"
    }
  }
};

export default function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [institutionCode, setInstitutionCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [validation, setValidation] = useState({ valid: true, error: "" });

  const validateInput = (value, type) => {
    if (type === "mobileNumber") {
      const mobileRegex = /^[0-9]{10}$/;
      if (!value) return "Mobile number is required";
      if (!mobileRegex.test(value)) return "Please enter a valid 10-digit mobile number";
    } else if (type === "password") {
      if (!value) return "Password is required";
    } else if (type === "institutionCode") {
      if (!value) return "Institution code is required";
    }
    return null;
  };

  const handleLogin = async () => {
    const mobileError = validateInput(mobileNumber, "mobileNumber");
    const passwordError = validateInput(password, "password");
    const codeError = validateInput(institutionCode, "institutionCode");

    if (mobileError || passwordError || codeError) {
      setValidation({ valid: false, error: mobileError || passwordError || codeError });
      toast.error(mobileError || passwordError || codeError);
      return;
    }

    try {
      const institutionAction = await dispatch(checkEntity({ code: institutionCode }));
      if (!institutionAction.payload.auth) {
        toast.error("Invalid Maktab code");
        return;
      }

      const loginCall = await dispatch(loginMobile({ 
        mobileNumber, 
        password, 
        code: institutionCode 
      }));

      if (!loginCall.payload.auth) {
        toast.error(loginCall.payload.message || "Login failed");
        return;
      }

      toast.success("Login successful");
      localStorage.setItem('user', JSON.stringify(loginCall.payload.user));
      navigate(loginCall.payload.user.role.name === "Parent" ? "/parent/home" : "/admin/dashboard");
    } catch (error) {
      console.log(error)
      alert(error)
      toast.error(error.response?.data?.message || "An error occurred during login");
    }
  };

  return (
    <div className="mb-5 flex flex-col items-center justify-center p-5 lg:flex-row lg:justify-start shadow-lg rounded-lg">
      <div className="w-full max-w-full flex flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <h4 className="mb-2.5 text-4xl font-bold text-center text-navy-700 dark:text-white">Sign In</h4>
          <p className="mb-5 ml-1 text-base text-gray-600 text-center">
            Enter your Maktab code, mobile number, and password to sign in!
          </p>

          <InputField
            variant="auth"
            extra="mb-3 w-full"
            label="Maktab Code*"
            placeholder="Enter your Maktab code"
            id="institutionCode"
            type="text"
            value={institutionCode}
            onChange={(e) => setInstitutionCode(e.target.value.toLowerCase())}
          />

          <InputField
            variant="auth"
            extra="mb-3"
            label="Mobile Number*"
            placeholder="Enter your 10-digit mobile number"
            id="mobileNumber"
            type="tel"
            maxLength="10"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value.replace(/\D/g, ''))}
          />

          <div className="relative">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Password*"
              placeholder="Enter your password"
              id="password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="button"
              className="absolute right-3 top-[50%] translate-y-[30%] text-gray-600"
              onClick={() => setShowPassword(!showPassword)}
            >
              <Eye closed={!showPassword} />
            </button>
          </div>

          <div className="mb-4 flex items-center justify-end">
            <Link to={`/auth/forget-password?code=${institutionCode}`} className="text-sm text-brand-500 hover:text-brand-600">
              Forgot Password?
            </Link>
          </div>

          <button onClick={handleLogin}
            className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Sign In
          </button>
        </motion.div>
        <Toaster />
      </div>
    </div>
  );
}
