import { React, useState, useEffect } from 'react'
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux'
import { getAttendances, addAttendance, updateAttendance, deleteAttendance } from '../../../redux/actions/admin/attendance';
import toast, { Toaster } from 'react-hot-toast';
import Paginate from 'components/paginate';
import { useNavigate } from 'react-router-dom';
import {
    FiSearch,
    FiEdit2,
    FiTrash,
    FiChevronLeft,
    FiChevronRight,
    FiCalendar
} from "react-icons/fi";
import {
    FaEye
} from "react-icons/fa";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../components/shadcn/table";

import DialogCustom from "../../../components/dialog";
import Form from "../../../components/form";

export const Attendance = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const attendances = useSelector((state) => state.attendance?.value?.data || { rows: [], count: 0 });
    const classes = useSelector((state) => state.classRoom?.value || { data: { rows: [] } });

    const [formData, setFormData] = useState({});
    const [logs, setLogs] = useState([]);
    const [user, setUser] = useState({});
    const [filter, setFilter] = useState({
        name: "",
        status: "All",
        limit: 10,
        page: 1,
        date: new Date()
    });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const notifySuccess = (message) => toast.success(message, {
        style: {
            padding: '35px',
            color: '#a0ca00',
        },
        duration: 3000,
        iconTheme: {
            primary: '#a0ca00',
            secondary: '#222c25',
        }
    })
    const notifyError = (message) => toast.error(message, {
        style: {
            border: '1px solid #fff',
            padding: '35px',
            color: 'red',
        },
        iconTheme: {
            primary: 'red',
            secondary: '#fff',
        }
    })

    useEffect(() => {
        dispatch(getAttendances(filter))
    }, [dispatch])


    const handleActionButtonClick = (attendanceId, action) => {
        if (action === 'edit') setIsDialogOpen(true);
        if (action === 'delete') setIsDeleteDialogOpen(true);
        if (action === 'add') {
            navigate('/admin/attendance/add');
        }
    };

    useEffect(() => {
        const formattedDate = moment(filter.date).format('YYYY-MM-DD');
        dispatch(getAttendances({ ...filter, date: formattedDate }));
    }, [filter]);

    const handleCrudAttendance = async () => {
        try {
            setIsDialogOpen(false)
            if (formData.id) await dispatch(updateAttendance(formData));
            if (!formData.id) await dispatch(addAttendance(formData));
            notifySuccess("Success")

            setFormData({});
            await dispatch(getAttendances({}));

        } catch (error) {
            console.error('Failed to add state:', error);
        }
    };

    const handleDelete = async () => {
        try {
            setIsDeleteDialogOpen(false)
            await dispatch(deleteAttendance(formData.id));
            // setFormData({});
            notifySuccess("Delete attendance successfully")
            dispatch(getAttendances(filter));

        } catch (error) {
            console.error('Failed to add state:', error);
        }
    };

    const fields = [
        {
            id: "name",
            name: "name",
            label: "Attendance Name",
            placeholder: "Enter Attendance's Name",
            type: "text",
        },
        {
            id: "classId",
            name: "classId",
            label: "Class",
            placeHolder: "Select Class",
            type: "dropdown",
            list: classes.data.rows,
            toSelect: "id",
            selectedvalue: "class",
        }
    ];

    const dialogContent = (
        <>
            <Table className="border-b border-grey-500">
                <TableCaption className="mb-3 font-bold text-2xl text-start text-black">
                    Logs
                </TableCaption>
                {logs?.length > 0 ? (
                    <div className="">
                        <TableHeader>

                            <TableRow className="dark:text-white">
                                <TableHead className="w-full"> Type </TableHead>
                                <TableHead className="w-full"> Time </TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {logs?.map((log) => (
                                <TableRow key={log.id}>
                                    <TableCell className={`font-medium ${log?.logType == "Check-in" ? "text-green-500" : "text-red-500"}`}>
                                        <h4 className=''>{log?.logType}</h4>
                                    </TableCell>
                                    <TableCell className="font-medium">
                                        <h4 className=''>{moment(log?.logTime).format("hh:mm")}</h4>
                                    </TableCell>
                                </TableRow>
                            ))}


                        </TableBody>
                    </div>
                ) : (
                    <div className='p-10 flex flex-col justify-center items-center'>
                        <p className='mb-5 font-semibold'> No Attendances Found! </p>
                        {/* <button
                                    onClick={() => handleActionButtonClick(null, "add")}
                                    className="border-2 text-white linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500 active:!bg-white/70">
                                    Add Attendance
                                </button> */}
                    </div>
                )}
            </Table>
        </>
    );

    const handlePrevDay = () => {
        setFilter(prev => ({
            ...prev,
            date: moment(prev.date).subtract(1, 'days').toDate()
        }));
    };

    const handleNextDay = () => {
        const nextDay = moment(filter.date).add(1, 'days');
        if (nextDay.isSameOrBefore(moment(), 'day')) {
            setFilter(prev => ({
                ...prev,
                date: nextDay.toDate()
            }));
        }
    };

    const calculateTotalWorkingHours = (logs) => {
        if (!logs || logs.length === 0) return "No logs available";
      
        let totalMinutes = 0;
      
        // Create a copy of the logs array to avoid mutating the original array
        const sortedLogs = [...logs].sort((a, b) => moment(a.logTime).diff(moment(b.logTime)));
      
        // Iterate through logs to calculate duration
        for (let i = 0; i < sortedLogs.length; i++) {
          if (sortedLogs[i].logType === "Check-in") {
            const checkInTime = moment(sortedLogs[i].logTime);
            const checkOutTime =
              sortedLogs[i + 1]?.logType === "Check-out"
                ? moment(sortedLogs[i + 1].logTime)
                : moment(); // Use current time if no "Check-out"
            totalMinutes += checkOutTime.diff(checkInTime, "minutes");
            if (sortedLogs[i + 1]?.logType === "Check-out") i++; // Skip the next log if it's "Check-out"
          }
        }
      
        // Convert total minutes to hours and minutes
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
      
        return `${hours}h ${minutes}m`;
      };

    return (
        <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4 dark:bg-navy-700">
            <Toaster />
            <div className="h-full w-full rounded-xl">
                <div className="flex justify-center mb-6">
                    <div className="flex items-center gap-4 bg-gray-50 p-2 rounded-xl shadow-sm">
                        <button
                            onClick={handlePrevDay}
                            className="flex items-center justify-center w-10 h-10 rounded-lg bg-white shadow-sm hover:bg-brand-50 transition-colors duration-200 text-gray-600 hover:text-brand-500"
                        >
                            <FiChevronLeft className="w-6 h-6" />
                        </button>

                        <div className="flex items-center gap-3 bg-white px-6 py-3 rounded-lg shadow-sm min-w-[200px] justify-center">
                            <FiCalendar className="w-5 h-5 text-brand-500" />
                            <span className="text-gray-700 font-medium text-lg">
                                {moment(filter.date).format('DD MMM YYYY')}
                            </span>
                        </div>

                        <button
                            onClick={handleNextDay}
                            className={`flex items-center justify-center w-10 h-10 rounded-lg shadow-sm transition-colors duration-200 ${moment(filter.date).isSame(moment(), 'day')
                                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                    : 'bg-white hover:bg-brand-50 text-gray-600 hover:text-brand-500'
                                }`}
                            disabled={moment(filter.date).isSame(moment(), 'day')}
                        >
                            <FiChevronRight className="w-6 h-6" />
                        </button>
                    </div>
                </div>

                <div className='mt-3 p-4 rounded-xl border-solid border-2 border-gray-100'>
                    <Table className="border-b border-grey-500">
                        <TableCaption className="mb-3 font-bold text-2xl text-start text-black">
                            <div className='flex justify-between items-center'>
                                <div className='flex justify-between w-1/2 items-center'>
                                    <div className="mr-5 p-4 flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white ">
                                        <p className="pl-3 pr-2 text-xl">
                                            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                                        </p>
                                        <input
                                            // value={filter.name}
                                            onChange={(e) => {
                                                setFilter({ ...filter, ["name"]: e.target.value })
                                            }}
                                            type="text"
                                            placeholder="Search..."
                                            className="block h-full w-full  bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                                        />
                                    </div>
                                </div>
                                {/* <div className='flex  justify-end'>
                                    <button
                                        onClick={() => handleActionButtonClick(null, "add")}
                                        className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                        Add Attendance
                                    </button>

                                </div> */}
                            </div>
                        </TableCaption>
                        {attendances?.rows?.length > 0 ? (
                            <div className="">
                                <TableHeader>

                                    <TableRow className="dark:text-white">
                                        <TableHead className="w-4/12"> Name </TableHead>
                                        <TableHead className="w-3/12"> Status </TableHead>
                                        <TableHead className="w-3/12"> Start Time </TableHead>
                                        <TableHead className="w-6/12"> Total Time </TableHead>
                                        <TableHead className="w-1/12 text-center">Actions</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {attendances.rows.map((attendance) => (
                                        <TableRow key={attendance.id}>
                                            <TableCell className="font-medium">
                                                <h4 className=''>{attendance?.user?.fullname}</h4>
                                            </TableCell>
                                            <TableCell className={`font-medium ${attendance?.attendancelogs[attendance?.attendancelogs.length - 1]?.logType == "Check-in" ? "text-green-500" : "text-red-500"}` }>
                                                {
                                                    attendance?.attendancelogs?.length &&
                                                        attendance?.attendancelogs[attendance?.attendancelogs.length - 1]?.logType == "Check-in" ? "On Duty" : "Off Duty"
                                                }
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <h4 className=''>{moment(attendance?.createdAt).format("hh:mm")}</h4>
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <h4 className=''>
                                                    { 
                                                        attendance?.attendancelogs ? 
                                                        calculateTotalWorkingHours(attendance?.attendancelogs) : 
                                                        "No logs available" 
                                                    }
                                                </h4>
                                            </TableCell>
                                            <TableCell className="w-1/6">
                                                <div className='w-full flex justify-around'>
                                                    <div
                                                        onClick={() => {
                                                            setLogs(attendance?.attendancelogs)
                                                            setUser(attendance?.user)
                                                            setIsDialogOpen(true)
                                                        }}
                                                        className='mx-2 bg-yellow-500 w-fit p-1 rounded-3xl cursor-pointer' >
                                                        <FaEye className='h-4 w-4 text-white' />
                                                    </div>
                                                    {/* <div
                                                        onClick={() => {
                                                            navigate(`/admin/permission/${attendance.id}`);
                                                        }}
                                                        className='mx-2 bg-blue-500 w-fit p-1 rounded-3xl cursor-pointer' >
                                                        <FiSettings className='h-4 w-4 text-white' />
                                                    </div> */}
                                                    {/* <div
                                                        onClick={() => handleActionButtonClick(attendance.id, "delete")}
                                                        className='mx-2 bg-red-500 w-fit p-1 rounded-3xl cursor-pointer'>
                                                        <FiTrash className='h-4 w-4 text-white' />
                                                    </div> */}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}


                                </TableBody>
                                <Paginate
                                    page={filter.page}
                                    totalCount={attendances.count || 0}
                                    limit={filter.limit}
                                    changePage={(value) => {
                                        setFilter({ ...filter, ["page"]: value })
                                    }}
                                />
                            </div>
                        ) : (
                            <div className='p-10 flex flex-col justify-center items-center'>
                                <p className='mb-5 font-semibold'> No Attendances Found! </p>
                                {/* <button
                                    onClick={() => handleActionButtonClick(null, "add")}
                                    className="border-2 text-white linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500 active:!bg-white/70">
                                    Add Attendance
                                </button> */}
                            </div>
                        )}
                    </Table>
                </div>
            </div>
            <DialogCustom
                open={isDialogOpen}
                onOpenChange={setIsDialogOpen}
                dialogTitle={`${user?.fullname} Attendance Logs`}
                dialogWidth="w-3/4"
                dialogDesc={ ` Attendances of ${user?.fullname} for ${moment(filter.date).format("DD-MM-YYYY")} ` }
                content={dialogContent}
            />
            <DialogCustom

                open={isDeleteDialogOpen}
                onOpenChange={setIsDeleteDialogOpen}
                dialogTitle="Delete Attendance"
                dialogWidth="w-1/2"
                dialogDesc="Are you sure you want to delete this attendance ?"
                content={
                    <div className='flex flex-row-reverse'>
                        <button
                            onClick={handleDelete}
                            className=" text-white linear rounded-xl border-2 border-white bg-red-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-red-500 hover:border-2 hover:border-red-700 hover:shadow-lg  ">
                            Delete
                        </button>
                        <button
                            onClick={() => {
                                setIsDeleteDialogOpen(false);
                            }}
                            className="mr-4 text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
                            Cancel
                        </button>
                    </div>
                }
            />
        </div>
    )
};

const styles = `
    .date-selector-container {
        background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1), rgba(255,255,255,0));
    }

    .date-nav-button {
        transition: all 0.2s ease-in-out;
    }

    .date-nav-button:hover:not(:disabled) {
        transform: translateY(-1px);
    }

    .date-nav-button:active:not(:disabled) {
        transform: translateY(1px);
    }
`;

const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default Attendance