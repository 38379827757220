
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const getTaskLogs = createAsyncThunk("tasklog/get", async (query) => {
  console.log(query)

  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
);
let params = new URLSearchParams(filteredQuery);

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/tasklog?${params.toString()}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const addTaskLog = createAsyncThunk("tasklog/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/tasklog/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});



export const updateTaskLog = createAsyncThunk("tasklog/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/tasklog/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});


export const deleteTaskLog = createAsyncThunk("tasklog/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/tasklog/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const sessionsSlice = createSlice({
  name: 'tasklog',
  initialState: {
  
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        rows: []
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTaskLogs.fulfilled, (tasklog, action) => {
     
        tasklog.value.data.rows = action.payload.rows
        tasklog.value.data.count = action.payload.count
        tasklog.value.status = action.payload.message
        console.log('Updated tasklog:', tasklog.value.data.rows);

        
      })
      .addCase(getTaskLogs.pending, (tasklog) => {
        tasklog.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getTaskLogs.rejected, (tasklog) => {
        tasklog.status = "Failed to  fetch data..."
      })
      .addCase(updateTaskLog.fulfilled, (tasklog, action) => {
        console.log(action.payload.message, "action.payload.message")
        tasklog.value.status = action.payload.message
        // find the index of the tasklog with the updated id
        // const index = tasklog.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the tasklog at the found index with the updated tasklog
        //   tasklog.value.data[index] = action.payload;
        // }
      })
      .addCase(updateTaskLog.pending, (tasklog) => {
        tasklog.status = "Updating tasklog. Please wait a moment...";
      })
      .addCase(updateTaskLog.rejected, (tasklog) => {
        tasklog.status = "Failed to update tasklog...";
      })
      .addCase(deleteTaskLog.fulfilled, (tasklog, action) => {
        // // filter out the tasklog with the deleted id
        // tasklog.value.data = tasklog.value.data.filter(item => item.id !== action.payload.id);
        tasklog.value.status = action.payload.message
      })
      .addCase(deleteTaskLog.pending, (tasklog) => {
        tasklog.status = "Deleting tasklog. Please wait a moment...";
      })
      .addCase(deleteTaskLog.rejected, (tasklog) => {
        tasklog.status = "Failed to delete tasklog...";
      })
      .addCase(addTaskLog.fulfilled, (tasklog, action) => {
        // tasklog.value.data.push(action.payload); // add the new tasklog to the list
     })
     .addCase(addTaskLog.pending, (tasklog) => {
        tasklog.status = "Adding tasklog. Please wait a moment...";
     })
     .addCase(addTaskLog.rejected, (tasklog) => {
        tasklog.status = "Failed to add tasklog...";
     });
  }
})

export default sessionsSlice.reducer
