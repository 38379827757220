import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from './headers';


export const getHomeScreenData = createAsyncThunk("homescreendata/get", async (studentId) => {
  console.log(studentId, "getHomeScreenData")
  if (!studentId) return 0
  let response = await fetch(`${process.env.REACT_APP_URL}user/api/home?student=${studentId}`, {
    headers: createHeaders()
  });
  let json = await response.json();
  return json;
});

export const getProfile = createAsyncThunk("profile/get", async (query) => {
  let response = await fetch(`${process.env.REACT_APP_URL}user/api/user`, {
    headers: createHeaders()
  });
  let json = await response.json();
  return json;
});

export const getStudyMaterial = createAsyncThunk("material/get", async (subjectId) => {
  let response = await fetch(`${process.env.REACT_APP_URL}user/api/home/getmaterial/${subjectId}`, {
    headers: createHeaders()
  });
  let json = await response.json();
  return json;
});

export const getCourseSubjects = createAsyncThunk("course/getsubjects", async (courseId) => {
  let response = await fetch(`${process.env.REACT_APP_URL}user/api/home/get-course-subjects/${courseId}`, {
    headers: createHeaders()
  });
  let json = await response.json();
  return json;
});

export const getClassStudents = createAsyncThunk("staff/students", async (classId) => {
  let response = await fetch(`${process.env.REACT_APP_URL}user/api/home/classstudents/${classId}`, {
    headers: createHeaders()
  });
  let json = await response.json();
  return json;
});

export const submitAttendance = createAsyncThunk("staff/submitattendance", async (attendance) => {
  let response = await fetch(`${process.env.REACT_APP_URL}user/api/home/attendance`, {
    method: "POST",
    headers: createHeaders(),
    body: JSON.stringify(attendance),
  });
  let json = await response.json();
  return json;
});

export const logStaffAttendance = createAsyncThunk("staff/staffattendance", async (logType) => {
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/attendance`, {
    method: "POST",
    headers: createHeaders(),
    body: JSON.stringify(logType),
  });
  let json = await response.json();
  return json;
});

export const fetchStaffAttendance = createAsyncThunk("staff/fetchstaffattendance", async () => {
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/attendance/todays-attendance`, {
    method: "GET",
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const fetchLessonLogs = createAsyncThunk("staff/fetchlessonlogs", async () => {
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/lesson/logs`, {
    method: "GET",
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const getNotifications = createAsyncThunk("notifications/get", async (query) => {
  console.log(query)

  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
);
let params = new URLSearchParams(filteredQuery);

let response = await fetch(`${process.env.REACT_APP_URL}user/api/home/notifications?${params.toString()}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});


export const homesSlice = createSlice({
  name: 'home',
  initialState: {
    value: {
      staffAttendance: {},
      subjects: [],
      enrollments: [],
      classStudents: [],
      notifications: {
        rows: [],
        count: 0
      },
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHomeScreenData.fulfilled, (state, action) => {
        const { payload } = action;
        console.log('payload payload: '+JSON.stringify(payload));
        if (payload) {
          state.value.subjects = payload.subjects || [];
          state.value.enrollments = payload.enrollments || [];
          state.value.enrolledClass = payload.enrolledClass || {};
          state.value.weeklyAttendance = payload.weeklyAttendance || [];
          state.value.todaysPendingTasks = payload.todaysPendingTasks || [];
          let presentCount = 0;
          let absentCount = 0;
          let totalCount = 0;

          payload.attendance.forEach(item => {
            // Loop through the attendances array for each item
            if(item.attendances.length == 0) absentCount ++
            item.attendances.forEach(attendance => {
              // Check the status and increment the respective count
              if (attendance.status === 'PRESENT') {
                presentCount++;
              } else if (attendance.status === 'ABSENT') {
                absentCount++;
              }
            });
          });
          state.value.presentCount = presentCount;
          state.value.absentCount = absentCount;
          totalCount = presentCount + absentCount;
          state.value.totalCount = presentCount + absentCount;
          state.value.presentPercentage = ((presentCount / totalCount) * 100).toFixed(0);
          state.value.absentPercentage = ((absentCount / totalCount) * 100).toFixed(0);
        }
      })
      .addCase(getStudyMaterial.fulfilled, (state, action) => {
        const { payload } = action;
          state.value.studyMaterial = payload;

      })
      .addCase(fetchStaffAttendance.fulfilled, (state, action) => {
        const { payload } = action;
          state.value.StaffAttendance = payload;

      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        const { payload } = action;
          // state.value.notifications.rows.push(...payload.rows);
          state.value.notifications.count = payload.count;
          state.value.notifications.rows = payload.rows;
      })
      .addCase(getClassStudents.fulfilled, (state, action) => {
        const { payload } = action;
        console.log(payload, "payload")
          // state.value.notifications.rows.push(...payload.rows);
          state.value.classStudents = payload;
      })
  }
})

export default homesSlice.reducer
