import { React, useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useLocation } from 'react-router-dom';


import Tabs from "../../../components/tabs";
import Subject from './subjects';
import Enrollment from './enrollments';
export const Course = (props) => {
    const location = useLocation();
    const {
        course
    } = location.state || {}
    const {
        setTitle
    } = props
    setTitle("course / " + course?.name )
    const navigate = useNavigate();


    

    return (
        <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4 dark:bg-navy-700">
            <Toaster />
            <div className="h-full w-full rounded-xl">
                <Tabs  
                  tabs={ ["Subjects", "Enrollments"] }
                  tabContents={ [<Subject />, <Enrollment /> ] }
                />               
            </div>
        </div>
    )
};

export default Course