
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const getFeesMasters = createAsyncThunk("feesmaster/get", async (query) => {
  console.log(query)

  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
);
let params = new URLSearchParams(filteredQuery);

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/feesmaster?${params.toString()}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const addFeesMaster = createAsyncThunk("feesmaster/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/feesmaster/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});



export const updateFeesMaster = createAsyncThunk("feesmaster/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/feesmaster/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});


export const deleteFeesMaster = createAsyncThunk("feesmaster/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/feesmaster/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const feesmasterSlice = createSlice({
  name: 'feesmaster',
  initialState: {
  
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        rows: []
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeesMasters.fulfilled, (feesmaster, action) => {
     
        feesmaster.value.data.rows = action.payload.rows
        feesmaster.value.data.count = action.payload.count
        feesmaster.value.status = action.payload.message
        console.log('Updated feesmaster:', feesmaster.value.data.rows);

        
      })
      .addCase(getFeesMasters.pending, (feesmaster) => {
        feesmaster.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getFeesMasters.rejected, (feesmaster) => {
        feesmaster.status = "Failed to  fetch data..."
      })
      .addCase(updateFeesMaster.fulfilled, (feesmaster, action) => {
        console.log(action.payload.message, "action.payload.message")
        feesmaster.value.status = action.payload.message
        // find the index of the feesmaster with the updated id
        // const index = feesmaster.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the feesmaster at the found index with the updated feesmaster
        //   feesmaster.value.data[index] = action.payload;
        // }
      })
      .addCase(updateFeesMaster.pending, (feesmaster) => {
        feesmaster.status = "Updating feesmaster. Please wait a moment...";
      })
      .addCase(updateFeesMaster.rejected, (feesmaster) => {
        feesmaster.status = "Failed to update feesmaster...";
      })
      .addCase(deleteFeesMaster.fulfilled, (feesmaster, action) => {
        // // filter out the feesmaster with the deleted id
        // feesmaster.value.data = feesmaster.value.data.filter(item => item.id !== action.payload.id);
        feesmaster.value.status = action.payload.message
      })
      .addCase(deleteFeesMaster.pending, (feesmaster) => {
        feesmaster.status = "Deleting feesmaster. Please wait a moment...";
      })
      .addCase(deleteFeesMaster.rejected, (feesmaster) => {
        feesmaster.status = "Failed to delete feesmaster...";
      })
      .addCase(addFeesMaster.fulfilled, (feesmaster, action) => {
        // feesmaster.value.data.push(action.payload); // add the new feesmaster to the list
     })
     .addCase(addFeesMaster.pending, (feesmaster) => {
        feesmaster.status = "Adding feesmaster. Please wait a moment...";
     })
     .addCase(addFeesMaster.rejected, (feesmaster) => {
        feesmaster.status = "Failed to add feesmaster...";
     });
  }
})

export default feesmasterSlice.reducer
