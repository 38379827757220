import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getEnrollments, addEnrollment, updateEnrollment, deleteEnrollment } from '../../../redux/actions/admin/enrollment';
import toast, { Toaster } from 'react-hot-toast';
import DropdownSelect from 'components/select';
import Paginate from 'components/paginate';
import { useNavigate, useParams } from 'react-router-dom';
import {
    FiTrendingUp,
    FiX,
    FiLayers,
    FiSearch,
    FiEdit2,
    FiTrash,
} from "react-icons/fi";
import { getClasses } from '../../../redux/actions/admin/classRoom';
import { getBatches } from '../../../redux/actions/admin/batch';
import { getSessions } from '../../../redux/actions/admin/session';
import { getStudents } from '../../../redux/actions/admin/student';

import Widget from "components/widget/Widget";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../components/shadcn/table";

import DialogCustom from "../../../components/dialog";
import Form from "../../../components/form";

export const Enrollment = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [formData, setFormData] = useState({});
    const [filter, setFilter] = useState({
        name: "",
        status: "All",
        courseId: id,
        limit: 10,
        page: 1
    });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedCourseValue, setSelectedCourseValue] = useState({});

    const notifySuccess = (message) => toast.success(message ?? 'Operation successful', {
        style: {
            padding: '35px',
            color: '#a0ca00',
        },
        duration: 3000,
        iconTheme: {
            primary: '#a0ca00',
            secondary: '#222c25',
        }
    });

    const notifyError = (message) => toast.error(message ?? 'An error occurred', {
        style: {
            border: '1px solid #fff',
            padding: '35px',
            color: 'red',
        },
        iconTheme: {
            primary: 'red',
            secondary: '#fff',
        }
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if (id) {
            dispatch(getEnrollments(filter));
        }
    }, [dispatch, id]);

    useEffect(() => {
        dispatch(getClasses({ limit: 50 }));
        dispatch(getBatches({ limit: 50 }));
        dispatch(getSessions({ limit: 50 }));
        dispatch(getStudents({ limit: 500 }));
    }, [dispatch]);

    const students = useSelector((state) => state.student?.value?.data ?? { rows: [] });
    const classes = useSelector((state) => state.classRoom?.value?.data ?? { rows: [] });
    const batches = useSelector((state) => state.batch?.value?.data ?? { rows: [] });
    const sessions = useSelector((state) => state.session?.value?.data ?? { rows: [] });
    const enrollments = useSelector((state) => state.enrollment?.value?.data ?? { rows: [], count: 0 });

    const handleActionButtonClick = (enrollmentId, action) => {
        if (action === 'edit' && enrollmentId) {
            const selectedEnrollment = enrollments.rows.find((enrollment) => enrollment.id === enrollmentId);
            if (selectedEnrollment) {
                setFormData(selectedEnrollment);
                setIsDialogOpen(true);
                setSelectedCourseValue(id);
            }
        } else if (action === 'delete' && enrollmentId) {
            const selectedEnrollment = enrollments.rows.find((enrollment) => enrollment.id === enrollmentId);
            if (selectedEnrollment) {
                setFormData(selectedEnrollment);
                setIsDeleteDialogOpen(true);
            }
        } else if (!enrollmentId && action === 'add') {
            setIsDialogOpen(true);
            setFormData({});
        }
    };

    useEffect(() => {
        if (id) {
            dispatch(getEnrollments(filter));
        }
    }, [filter, id]);

    const handleCrudEnrollment = async () => {
        try {
            setIsDialogOpen(false);
            if (!id) {
                notifyError("Course ID is required");
                return;
            }

            if (formData.id) {
                await dispatch(updateEnrollment({ ...formData, courseId: id }));
            } else {
                await dispatch(addEnrollment({ ...formData, courseId: id }));
            }
            notifySuccess("Operation completed successfully");

            setFormData({});
            await dispatch(getEnrollments(filter));
        } catch (error) {
            console.error('Failed to process enrollment:', error);
            notifyError(error?.message ?? 'Failed to process enrollment');
        }
    };

    const handleDelete = async () => {
        try {
            if (!formData?.id) {
                notifyError("Invalid enrollment selected");
                return;
            }

            setIsDeleteDialogOpen(false);
            await dispatch(deleteEnrollment(formData.id));
            notifySuccess("Enrollment deleted successfully");
            await dispatch(getEnrollments(filter));
        } catch (error) {
            console.error('Failed to delete enrollment:', error);
            notifyError(error?.message ?? 'Failed to delete enrollment');
        }
    };

    const fields = [
        {
            id: "batchId",
            name: "batchId",
            label: "Batch",
            placeHolder: "Select Batch",
            type: "dropdown",
            list: batches.rows ?? [],
            toSelect: "id",
            selectedvalue: "batch",
        },
        {
            id: "sessionId",
            name: "sessionId",
            label: "Session",
            placeHolder: "Select Session",
            type: "dropdown",
            list: sessions.rows ?? [],
            toSelect: "id",
            selectedvalue: "session",
        },
        {
            id: "classId",
            name: "classId",
            label: "Class",
            placeHolder: "Select Class",
            type: "dropdown",
            list: classes.rows ?? [],
            toSelect: "id",
            selectedvalue: "class",
        },
        {
            id: "Status",
            name: "status",
            label: "Status",
            desc: "Set status as active/inactive",
            type: "switch",
        },
        {
            id: "studentId",
            name: "studentId",
            label: "Student",
            placeHolder: "Select Student",
            type: "dropdown",
            list: students.rows ?? [],
            toSelect: "id",
            selectedvalue: "student",
            toShow: ['admission', 'name']
        },
    ];

    return (
        <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white dark:bg-navy-700">
            <Toaster />
            <div className="h-full w-full rounded-xl">
                <div className='mt-3 p-4 rounded-xl border-solid border-2 border-gray-100'>
                    <Table className="border-b border-grey-500">
                        <TableCaption className="mb-3 font-bold text-2xl text-start text-black">
                            <div className='flex justify-between items-center'>
                                <div className='flex justify-between w-1/2 items-center'>
                                    <div className="mr-5 p-4 flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white">
                                        <p className="pl-3 pr-2 text-xl">
                                            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                                        </p>
                                        <input
                                            onChange={(e) => setFilter({ ...filter, name: e.target.value })}
                                            type="text"
                                            placeholder="Search..."
                                            className="block h-full w-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                                        />
                                    </div>
                                </div>
                                <div className='flex justify-end'>
                                    <button
                                        onClick={() => handleActionButtonClick(null, "add")}
                                        className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500 active:!bg-white/70">
                                        Add Enrollment
                                    </button>
                                </div>
                            </div>
                        </TableCaption>
                        {enrollments.rows && enrollments.rows.length > 0 ? (
                            <div className="">
                                <TableHeader>
                                    <TableRow className="dark:text-white">
                                        <TableHead className="w-4/12">Student</TableHead>
                                        <TableHead className="w-4/12">Course</TableHead>
                                        <TableHead className="w-4/12">Details</TableHead>
                                        <TableHead className="w-1/3 text-center">Actions</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {enrollments.rows.map((enrollment) => (
                                        <TableRow key={enrollment?.id}>
                                            <TableCell className="font-medium">
                                                <h4 className=''>{enrollment?.student?.admission?.name ?? 'N/A'}</h4>
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <h4 className=''>{enrollment?.course?.name ?? 'N/A'}</h4>
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <h4 className=''>{`${enrollment?.class?.name ?? 'N/A'} - ${enrollment?.session?.name ?? 'N/A'}`}</h4>
                                            </TableCell>
                                            <TableCell className="w-1/6">
                                                <div className='w-full flex justify-around'>
                                                    <div
                                                        onClick={() => handleActionButtonClick(enrollment.id, "edit")}
                                                        className='mx-2 bg-yellow-500 w-fit p-1 rounded-3xl cursor-pointer'>
                                                        <FiEdit2 className='h-4 w-4 text-white' />
                                                    </div>
                                                    <div
                                                        onClick={() => handleActionButtonClick(enrollment.id, "delete")}
                                                        className='mx-2 bg-red-500 w-fit p-1 rounded-3xl cursor-pointer'>
                                                        <FiTrash className='h-4 w-4 text-white' />
                                                    </div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <Paginate
                                    page={filter.page}
                                    totalCount={enrollments.count}
                                    limit={filter.limit}
                                    changePage={(value) => setFilter({ ...filter, page: value })}
                                />
                            </div>
                        ) : (
                            <div className='p-10 flex flex-col justify-center items-center'>
                                <p className='mb-5 font-semibold'>No Enrollments Found!</p>
                                <button
                                    onClick={() => handleActionButtonClick(null, "add")}
                                    className="border-2 text-white linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500 active:!bg-white/70">
                                    Add Enrollment
                                </button>
                            </div>
                        )}
                    </Table>

                    <DialogCustom
                        open={isDialogOpen}
                        onOpenChange={setIsDialogOpen}
                        dialogTitle={formData.id ? "Edit Enrollment" : "Add Enrollment"}
                        dialogWidth="w-1/2"
                        dialogDesc={formData.id ? "Update the necessary fields and save." : "Fill in the required fields and save."}
                        content={
                            <>
                                <Form
                                    fields={fields}
                                    formData={formData}
                                    onChange={(newFormData) => setFormData(newFormData)}
                                />
                                <div className='flex justify-end'>
                                    <button
                                        onClick={handleCrudEnrollment}
                                        className="text-black linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-brand-850/80 hover:text-white active:!bg-white/70">
                                        {formData.id ? "Update" : "Add"} Enrollment
                                    </button>
                                </div>
                            </>
                        }
                    />

                    <DialogCustom
                        open={isDeleteDialogOpen}
                        onOpenChange={setIsDeleteDialogOpen}
                        dialogTitle="Delete Enrollment"
                        dialogWidth="w-1/2"
                        dialogDesc="Are you sure you want to delete this enrollment?"
                        content={
                            <div className='flex flex-row-reverse'>
                                <button
                                    onClick={handleDelete}
                                    className="text-white linear rounded-xl border-2 border-white bg-red-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-red-500 hover:border-2 hover:border-red-700 hover:shadow-lg">
                                    Delete
                                </button>
                                <button
                                    onClick={() => setIsDeleteDialogOpen(false)}
                                    className="mr-4 text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg">
                                    Cancel
                                </button>
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default Enrollment;