import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IoEnter } from "react-icons/io5";

const Enrollments = () => {
  const navigate = useNavigate();
  const home = useSelector((home) => home.home.value);
  const { enrollments } = home;

  return (
    <div className="min-h-screen bg-lightPrimary p-4">
      <div className="max-w-4xl mx-auto">
        <div className="bg-white rounded-2xl shadow-lg p-2 space-y-4">
          {enrollments.length === 0 ? (
            <div className="flex flex-col items-center justify-center py-12">
              <div className="text-gray-400 text-lg mb-2">No enrollments found</div>
              <p className="text-sm text-gray-500">Enroll in a course to get started</p>
            </div>
          ) : (
            <div className="grid gap-4">
              {enrollments.map((enrollment) => (
                <div
                  key={enrollment.id}
                  onClick={() => navigate(`/parent/course/${enrollment.course.id}`)}
                  className="group relative bg-white border border-brand-500 hover:border-brand-500 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 p-4 cursor-pointer"
                >
                  <div className="flex items-center justify-between">
                    <div className="space-y-2">
                      <div className="flex items-center space-x-2">
                        <h2 className="text-lg font-semibold text-gray-800">
                          {enrollment?.course?.name}
                        </h2>
                        <span className={`px-2 py-1 text-xs font-medium  bg-opacity-10 rounded-full ${ enrollment.status ? "bg-brand-500 text-brand-500" : "bg-red-500 text-red-500" }`}>
                          { enrollment.status ? "Active" : "In-active" }
                        </span>
                      </div>
                      
                      <div className="space-y-1">
                        <p className="text-sm text-gray-600 flex items-center">
                          <span className="w-20 text-gray-500">Class:</span>
                          {enrollment?.class?.name}
                        </p>
                        <p className="text-sm text-gray-600 flex items-center">
                          <span className="w-20 text-gray-500">Session:</span>
                          {enrollment?.session?.name}
                        </p>
                        <p className="text-sm text-gray-600 flex items-center">
                          <span className="w-20 text-gray-500">Batch:</span>
                          {enrollment?.batch?.name}
                        </p>
                      </div>
                    </div>

                    <div className="transform group-hover:scale-105 transition-transform duration-300">
                      <div className="bg-brand-500 p-3 text-white rounded-full shadow-lg">
                        <IoEnter className="text-2xl" />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Enrollments;