
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const getFees = createAsyncThunk("fees/get", async (query) => {
  console.log(query)

  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
);
let params = new URLSearchParams(filteredQuery);

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/fees?${params.toString()}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const addFee = createAsyncThunk("fees/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/fees/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});

export const updateFeesLogs = createAsyncThunk("fees/updatelogs", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/fees/logs`, {
    method: 'PUT',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});



export const updateFee = createAsyncThunk("fees/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/fees/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});

export const sendFeesDueNotification = createAsyncThunk("fees/notifydue", async (data) => {
  // console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/fees/notifydue`, {
    method: 'POST', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});


export const deleteFee = createAsyncThunk("fees/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/fees/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const feesSlice = createSlice({
  name: 'fees',
  initialState: {
  
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        rows: []
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFees.fulfilled, (fees, action) => {
     
        fees.value.data.rows = action.payload.rows
        fees.value.data.count = action.payload.count
        fees.value.status = action.payload.message
        console.log('Updated fees:', fees.value.data.rows);

        
      })
      .addCase(getFees.pending, (fees) => {
        fees.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getFees.rejected, (fees) => {
        fees.status = "Failed to  fetch data..."
      })
      .addCase(updateFee.fulfilled, (fees, action) => {
        console.log(action.payload.message, "action.payload.message")
        fees.value.status = action.payload.message
        // find the index of the fees with the updated id
        // const index = fees.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the fees at the found index with the updated fees
        //   fees.value.data[index] = action.payload;
        // }
      })
      .addCase(updateFee.pending, (fees) => {
        fees.status = "Updating fees. Please wait a moment...";
      })
      .addCase(updateFee.rejected, (fees) => {
        fees.status = "Failed to update fees...";
      })
      .addCase(deleteFee.fulfilled, (fees, action) => {
        // // filter out the fees with the deleted id
        // fees.value.data = fees.value.data.filter(item => item.id !== action.payload.id);
        fees.value.status = action.payload.message
      })
      .addCase(deleteFee.pending, (fees) => {
        fees.status = "Deleting fees. Please wait a moment...";
      })
      .addCase(deleteFee.rejected, (fees) => {
        fees.status = "Failed to delete fees...";
      })
      .addCase(addFee.fulfilled, (fees, action) => {
        // fees.value.data.push(action.payload); // add the new fees to the list
     })
     .addCase(addFee.pending, (fees) => {
        fees.status = "Adding fees. Please wait a moment...";
     })
     .addCase(addFee.rejected, (fees) => {
        fees.status = "Failed to add fees...";
     });
  }
})

export default feesSlice.reducer
