
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const getAdmissions = createAsyncThunk("admission/get", async (query) => {
  console.log(query)

  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
);
let params = new URLSearchParams(filteredQuery);

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/admission?${params.toString()}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const getAllAdmissions = createAsyncThunk("alladmission/get", async () => {

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/admission/all`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const getAdmission = createAsyncThunk("single-admission/get", async (id) => {

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/admission/${id}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const addAdmission = createAsyncThunk("admission/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/admission/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});

export const addAdmissionBulk = createAsyncThunk("admission/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/admission/bulk`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});

export const approveAdmission = createAsyncThunk("admission/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/admission/approve`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});

export const postOpenAdmission = createAsyncThunk("admission/add", async (query) => {
  const { code, formData } = query
  console.log(formData, "formData")
  const response = await fetch(`${process.env.REACT_APP_URL}admin/open/admission/${code}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  });
  const json = await response.json();
  return json;
});



export const updateAdmission = createAsyncThunk("admission/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/admission/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});


export const deleteAdmission = createAsyncThunk("admission/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/admission/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const admissionsSlice = createSlice({
  name: 'admissiones',
  initialState: {
  
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        rows: []
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdmissions.fulfilled, (admission, action) => {
     
        admission.value.data.rows = action.payload.rows
        admission.value.data.count = action.payload.count
        admission.value.status = action.payload.message
        console.log('Updated admission:', admission.value.data.rows);

        
      })
      .addCase(getAdmission.fulfilled, (admission, action) => {
     
        admission.value.singleAdmission = action.payload

        if (admission.value.singleAdmission && admission.value.singleAdmission.customfields) {
          // Destructure customfields from singleAdmission
          const { customfields, ...rest } = admission.value.singleAdmission;
          // Merge rest of the properties with customfields
          admission.value.singleAdmission = { ...rest, ...customfields };
        }
        
      })
      .addCase(getAllAdmissions.fulfilled, (admission, action) => {
        admission.value.allAdmissions = action.payload
      })
      .addCase(getAdmissions.pending, (admission) => {
        admission.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getAdmissions.rejected, (admission) => {
        admission.status = "Failed to  fetch data..."
      })
      .addCase(updateAdmission.fulfilled, (admission, action) => {
        console.log(action.payload.message, "action.payload.message")
        admission.value.status = action.payload.message
        // find the index of the admission with the updated id
        // const index = admission.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the admission at the found index with the updated admission
        //   admission.value.data[index] = action.payload;
        // }
      })
      .addCase(updateAdmission.pending, (admission) => {
        admission.status = "Updating admission. Please wait a moment...";
      })
      .addCase(updateAdmission.rejected, (admission) => {
        admission.status = "Failed to update admission...";
      })
      .addCase(deleteAdmission.fulfilled, (admission, action) => {
        // // filter out the admission with the deleted id
        // admission.value.data = admission.value.data.filter(item => item.id !== action.payload.id);
        admission.value.status = action.payload.message
      })
      .addCase(deleteAdmission.pending, (admission) => {
        admission.status = "Deleting admission. Please wait a moment...";
      })
      .addCase(deleteAdmission.rejected, (admission) => {
        admission.status = "Failed to delete admission...";
      })
      .addCase(addAdmission.fulfilled, (admission, action) => {
        // admission.value.data.push(action.payload); // add the new admission to the list
     })
     .addCase(addAdmission.pending, (admission) => {
        admission.status = "Adding admission. Please wait a moment...";
     })
     .addCase(addAdmission.rejected, (admission) => {
        admission.status = "Failed to add admission...";
     })
    //  .addCase(approveAdmission.fulfilled, (admission) => {
    //   admission.value.singleAdmission
    //  });
  }
})

export default admissionsSlice.reducer
