import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import { FiChevronLeft, FiChevronRight, FiCalendar } from "react-icons/fi";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const WeeklyAttendanceChart = ({ data, setDates }) => {
  const [isLoading, setIsLoading] = useState(!data?.length);
  // Initialize the filter state
  const [filter, setFilter] = useState({
    fromDate: moment().startOf('week').toDate(),
    toDate: moment().endOf('week').toDate(),
  });

  const [filteredData, setFilteredData] = useState([]);

  // Update filteredData whenever filter changes or data loads
  useEffect(() => {
    setIsLoading(!data?.length);
    const filtered = data?.filter((item) =>
      moment(item.date).isBetween(filter.fromDate, filter.toDate, 'day', '[]')
    ) || [];
    setFilteredData(filtered);
    setDates(filter);
  }, [filter, data]);

  const handlePrevWeek = () => {
    setFilter((prev) => ({
      fromDate: moment(prev.fromDate).subtract(7, 'days').toDate(),
      toDate: moment(prev.toDate).subtract(7, 'days').toDate(),
    }));
  };

  const handleNextWeek = () => {
    if (!moment(filter.toDate).isSameOrAfter(moment(), 'day')) {
      setFilter((prev) => ({
        fromDate: moment(prev.fromDate).add(7, 'days').toDate(),
        toDate: moment(prev.toDate).add(7, 'days').toDate(),
      }));
    }
  };

  // Chart data and options
  const chartData = {
    labels: filteredData.map((item) => moment(item.date).format('ddd')),
    datasets: [
      {
        label: 'Present',
        data: filteredData.map((item) => item.totalPresentCount),
        backgroundColor: 'rgba(159, 203, 1, 0.85)',
        borderColor: 'rgba(75, 192, 192, 1)',
      },
      {
        label: 'Absent',
        data: filteredData.map((item) => item.totalAbsentCount),
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
        borderColor: 'rgba(255, 99, 132, 1)',
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Weekly Attendance',
      },
    },
    scales: {
      x: { stacked: true },
      y: { stacked: true, beginAtZero: true },
    },
    animation: {
      duration: 0,
    },
  };

  if (isLoading) {
    return (
      <div className="rounded-[20px] bg-white p-6 shadow-lg">
        <div className="flex justify-between">
          <div className="h-8 w-48 bg-gray-200 rounded animate-pulse" />
          <div className="flex items-center gap-4">
            <div className="h-8 w-8 bg-gray-200 rounded-full animate-pulse" />
            <div className="h-8 w-32 bg-gray-200 rounded animate-pulse" />
            <div className="h-8 w-8 bg-gray-200 rounded-full animate-pulse" />
          </div>
        </div>
        <div className="mt-8 h-[400px] bg-gray-200 rounded animate-pulse" />
      </div>
    );
  }

  return (
    <div className="rounded-[20px] bg-white p-6 shadow-lg">
      <div className='flex justify-between'>
        <h4 className="text-xl font-bold mb-4">Weekly Attendance</h4>
        <div className="flex items-center gap-4 mb-4">
          <button
            onClick={handlePrevWeek}
            className="p-2 bg-brand-500 rounded-full hover:bg-gray-200"
          >
            <FiChevronLeft />
          </button>
          <div className="flex items-center gap-2">
            <FiCalendar />
            <span>
              {moment(filter.fromDate).format('DD MMM')} -{' '}
              {moment(filter.toDate).format('DD MMM')}
            </span>
          </div>
          <button
            onClick={handleNextWeek}
            className={`p-2 rounded-full ${moment(filter.toDate).isSameOrAfter(moment(), 'day') ? 'bg-gray-100 cursor-not-allowed' : 'bg-brand-500 hover:bg-gray-200'}`}
            disabled={moment(filter.toDate).isSameOrAfter(moment(), 'day')}
          >
            <FiChevronRight />
          </button>
        </div>
      </div>
      <div style={{ height: 'calc(100% - 80px)' }}>
        <Bar data={chartData} options={chartOptions} style={{ width: "100%", maxHeight: "400px" }} />
      </div>
    </div>
  );
};

export default WeeklyAttendanceChart;