import { React, useEffect, useState } from 'react'
// import { Card, CardContent } from '../../components/shadcn';
import {
    FaTasks,
} from "react-icons/fa";
import { BookOpen, CalendarCheck, CalendarX, Cross, ListChecks, ListMinus, ListMinusIcon, X } from 'lucide-react';
import { ArrowUp, ArrowDown, Clock, Calendar, CheckCircle, XCircle, UserCircle } from 'lucide-react';
import PieChart from '../../components/charts/PieChart';
import { useDispatch, useSelector } from 'react-redux'
import { getHomeScreenData, logStaffAttendance, fetchStaffAttendance } from "../../redux/actions/home"
import { set } from 'react-hook-form';
// import { fetchAttendance } from '../../redux/actions/admin/';

function Home(props) {
    const { user, student } = props
    // console.log(student, "student");
    const [currentTime, setCurrentTime] = useState(new Date());
    // const staff = useSelector((user) => user.auth.value.profile);
    const dispatch = useDispatch()
    const home = useSelector((home) => home.home.value);
    const pieChartOptions = {
        labels: ["Present", "Absent"],
        colors: ["#589e00", "#dc2626"],
        chart: {
            width: "50px",
        },
        states: {
            hover: {
                filter: {
                    type: "none",
                },
            },
        },
        legend: {
            show: true,
        },
        dataLabels: {
            enabled: true,
        },
        hover: { mode: null },
        plotOptions: {
            donut: {
                expandOnClick: false,
                donut: {
                    labels: {
                        show: true,
                    },
                },
            },
        },
        fill: {
            colors: ["#589e00", "#dc2626"],
        },
        tooltip: {
            enabled: true,
            theme: "dark",
            style: {
                fontSize: "12px",
                fontFamily: undefined,
                backgroundColor: "#000000"
            },
        },
    };
    const pieChartTaskOptions = {
        labels: ["Complete", "InComplete"],
        colors: ["#589e00", "#dc2626"],
        chart: {
            width: "50px",
        },
        states: {
            hover: {
                filter: {
                    type: "none",
                },
            },
        },
        legend: {
            show: true,
        },
        dataLabels: {
            enabled: true,
        },
        hover: { mode: null },
        plotOptions: {
            donut: {
                expandOnClick: false,
                donut: {
                    labels: {
                        show: true,
                    },
                },
            },
        },
        fill: {
            colors: ["#589e00", "#dc2626"],
        },
        tooltip: {
            enabled: true,
            theme: "dark",
            style: {
                fontSize: "12px",
                fontFamily: undefined,
                backgroundColor: "#000000"
            },
        },
    };

    const pieChartData = [home.presentPercentage || 0, home.absentPercentage || 0];
    const pieChartTaskData = [home.presentPercentage + 30 || 0, home.absentPercentage - 10 || 0];
    useEffect(() => {
        // console.log("student inside UE :" + JSON.stringify(student));
        dispatch(getHomeScreenData(student?.id));
    }, [student]);

    const [pendingTasks, setPendingTasks] = useState(0);
    const [completedTasks, setCompletedTasks] = useState(0);
    useEffect(() => {

        home?.todaysPendingTasks?.forEach((task) => {
            if (task?.tasklogs?.length === 0) {
                setPendingTasks(pendingTasks + 1);
            } else {
                setCompletedTasks(completedTasks + 1);
            }
        })

    }, [home]);

    // console.log(home.weeklyAttendance, "home.weeklyAttendance");


    return (
        user && user.role &&
        <div className=' bg-lightPrimary'>
            <div className="p-2">
                <div className="bg-white shadow-xl rounded-xl mb-2 p-6 border border-slate-700">
                    <div className="flex items-center justify-between">
                        {/* Left side content */}
                        <div className="space-y-2">
                            <p className="text-slate-300 text-sm">
                                Assalamu alaikum,
                            </p>
                            {
                                user.role.name == "Staff" ?
                                    <h1 className="text-brand-500 text-2xl font-semibold tracking-tight">
                                        {user?.fullname || 'Name'}
                                    </h1> :
                                    <h1 className="text-brand-500 text-2xl font-semibold tracking-tight">
                                        {student?.admission?.name || 'Name'}
                                    </h1>
                            }

                            <div className="flex items-center gap-2 mt-1">
                                <div className="h-1.5 w-1.5 bg-blue-400 rounded-full"></div>
                                <p className="text-slate-300 text-sm">
                                    {new Date().toLocaleDateString('en-US', {
                                        weekday: 'long',
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    })}
                                </p>
                            </div>
                        </div>

                        {/* Right side - Profile icon */}
                        <div style={{ width: "75px", height: "75px" }} className='bg-brand-500 shadow p-3 h-full w-6/12 text-white rounded-full flex justify-center items-center'>
                            {/* <FaUser className='text-4xl' /> */}
                            {student?.admission?.profile ? 
                            <img src={`${process.env.REACT_APP_URL + "images" + student.admission.profile}`} alt="" className='w-full h-full object-cover rounded-full' /> : 
                            <UserCircle className='text-4xl' />}
                        </div>
                        {/* <div className="relative">
                        </div> */}
                    </div>
                </div>
            </div>
            {
                user && user.role.name == "Parent" &&
                (
                    user && user.role &&
                    <div className=' bg-lightPrimary'>


                        {
                            user && user.role.name == "Parent" &&
                            <>

                                <div className="grid grid-cols-2 gap-2  mx-2 mb-3">

                                <div className="group rounded-xl bg-white p-2  px-2 shadow-lg transition-all hover:-translate-y-1 hover:shadow-x l flex items-center justify-between">
                                        <div className="flex items-center">
                                            <div className="ml-3 rounded-full bg-gradient-to-r from-purple-500 to-purple-600 p-3 shadow-lg group-hover:scale-110">
                                                <BookOpen className="h-6 w-6 text-white" />
                                            </div>
                                            <div className="ml-4">
                                                <p className="text-sm font-medium text-gray-500"> Enrollments </p>
                                                <h3 className="text-lg font-bold text-gray-800">{home?.enrollments?.length || 0}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="group rounded-xl bg-white p-4 px-2 shadow-lg transition-all hover:-translate-y-1 hover:shadow-xl flex items-center justify-between">
                                        <div className="flex items-center">
                                            <div className="ml-0.5 rounded-full bg-gradient-to-r from-brand-500 to-brand-600 p-3 shadow-lg group-hover:scale-110">
                                                <CalendarCheck className="h-6 w-6 text-white" />
                                            </div>
                                            <div className="ml-4">
                                                <p className="text-sm font-medium text-gray-500"> Present % </p>
                                                <h3 className="text-lg font-bold text-gray-800">{home.presentPercentage || 0} %</h3>
                                            </div>
                                        </div>
                                    </div>
                                    

                                    
                                </div>
                                <div className="grid grid-cols-2 gap-2  mx-2 mb-3">

                                <div className="group rounded-xl bg-white p-2 px-2 shadow-lg transition-all hover:-translate-y-1 hover:shadow-xl flex items-center justify-between">
                                        <div className="flex items-center">
                                            <div className="ml-3 rounded-full bg-gradient-to-r from-red-500 to-red-600 p-3 shadow-lg group-hover:scale-110">
                                                <FaTasks className="h-6 w-6 text-white" />
                                            </div>
                                            <div className="ml-4">
                                                <p className="text-sm font-medium text-gray-500"> Todays Pending Tasks </p>
                                                <h3 className="text-lg font-bold text-gray-800">{pendingTasks || 0}</h3>
                                            </div>
                                        </div>
                                    </div>

                                    

                                    <div className="group rounded-xl bg-white p-2  px-2 shadow-lg transition-all hover:-translate-y-1 hover:shadow-x l flex items-center justify-between">
                                        <div className="flex items-center">
                                            <div className="rounded-full bg-gradient-to-r from-red-500 to-red-600 p-3 shadow-lg group-hover:scale-110">
                                                <X className="h-6 w-6 text-white" />
                                            </div>
                                            <div className="ml-4">
                                                <p className="text-sm font-medium text-gray-500"> Todays Completed Tasks </p>
                                                <h3 className="text-lg font-bold text-gray-800">{completedTasks || 0}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rounded-xl bg-white p-4 m-2 shadow-lg mb-3">
                                    <h2 className="my-2 font-medium text-base">This Week's Attendance </h2>
                                    {
                                        home?.weeklyAttendance?.length &&
                                        <>
                                            <h2 className="my-2 font-medium text-xs text-gray-500"> {`${home?.weeklyAttendance[0]?.date} to ${home?.weeklyAttendance[home?.weeklyAttendance?.length - 1]?.date} `} </h2>
                                            <div className="grid grid-cols-7 gap-2">
                                                {home?.weeklyAttendance?.map((day) => (
                                                    <div
                                                        key={day.date}
                                                        className={`flex flex-col items-center rounded-lg p-2 transition-all hover:scale-105 ${day.totalPresentCount > 0
                                                            ? 'bg-gradient-to-br from-green-100 to-green-50 text-green-800'
                                                            : day.totalAbsentCount > 0
                                                                ? 'bg-gradient-to-br from-red-100 to-red-50 text-red-800'
                                                                : 'bg-gradient-to-br from-gray-100 to-gray-50 text-gray-800'
                                                            }`}
                                                    >
                                                        <span className="text-sm font-medium">{day.day}</span>
                                                        <span className="mt-1 text-[10px] opacity-75">
                                                            {day.totalPresentCount > 0 ? 'Present' : day.totalAbsentCount > 0 ? 'Absent' : '-'}
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    }

                                </div>
                                <ModernAttendanceDisplay student={student} presentPercentage={home.presentPercentage} absentPercentage={home.absentPercentage} />
                            </>
                        }
                        {user && user.role && user.role.name === "Staff" && (
                            <StaffAttendance />
                        )}


                    </div>
                )
            }
            {user && user.role && user.role.name === "Staff" && (
                <StaffAttendance />
            )}


        </div>
    )
}


const StaffAttendance = () => {
    const [isCheckedIn, setIsCheckedIn] = useState(false);
    const [checkInTime, setCheckInTime] = useState(null);
    const [checkOutTime, setCheckOutTime] = useState(null);
    const [elapsedTime, setElapsedTime] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchStaffAttendance()).then(response => {
            if (response.payload) {
                const { attendancelogs } = response.payload;
                if (attendancelogs.length === 0) return;
                const lastLog = attendancelogs[attendancelogs.length - 1];
                setIsCheckedIn(lastLog?.logType === 'Check-in');
                if (lastLog?.logType === 'Check-in') {
                    setCheckInTime(new Date(lastLog?.logTime));
                } else {
                    setCheckOutTime(new Date(lastLog?.logTime));
                }
            }
        });
    }, []);

    const attendance = useSelector((home) => home.home.value.staffAttendance);

    useEffect(() => {
        console.log(attendance, "attendance");
    }, [attendance]);

    useEffect(() => {
        let timer;
        if (isCheckedIn && checkInTime) {
            timer = setInterval(() => {
                const now = new Date();
                const diff = now - checkInTime;
                const hours = Math.floor(diff / (1000 * 60 * 60));
                const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((diff % (1000 * 60)) / 1000);
                setElapsedTime(
                    `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
                );
            }, 1000);
        } else {
            setElapsedTime('');
        }

        return () => clearInterval(timer);
    }, [isCheckedIn, checkInTime]);

    const handleAttendance = () => {
        if (!isCheckedIn) {
            dispatch(logStaffAttendance({ logType: "Check-in" }));
            setCheckInTime(new Date());
        } else {
            dispatch(logStaffAttendance({ logType: "Check-out" }));
            setCheckOutTime(new Date());
        }
        setIsCheckedIn(!isCheckedIn);
    };

    return (
        <div className="bg-slate-100 flex justify-center items-center p-2">
            <div className="w-full max-w-md bg-white rounded-2xl shadow-lg overflow-hidden border border-slate-200">
                {/* Header Section */}
                <div className="px-6 py-5 bg-slate-800 border-b">
                    <div className="flex items-center gap-4">
                        <div className="p-2.5 bg-brand-500 rounded-full">
                            <UserCircle className="w-7 h-7 text-white" />
                        </div>
                        <div>
                            <h2 className="text-brand-500 text-lg font-semibold">Staff Attendance</h2>
                            <p className="text-slate-300 text-sm mt-0.5">
                                {new Date().toLocaleDateString('en-US', {
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="p-6 space-y-6 bg-white">
                    {/* Status Section */}
                    <div className="flex justify-center">
                        <div
                            className={`
                                flex items-center gap-2.5 px-5 py-2.5 rounded-full
                                transition-all duration-300 
                                ${isCheckedIn
                                    ? 'bg-green-100 text-green-800 border border-green-200'
                                    : 'bg-red-100 text-red-800 border border-red-200'
                                }
                            `}
                        >
                            {isCheckedIn ? (
                                <CheckCircle className="w-5 h-5 text-green-600" />
                            ) : (
                                <XCircle className="w-5 h-5 text-red-600" />
                            )}
                            <span className="font-medium">
                                {isCheckedIn ? 'Currently On Duty' : 'Currently Off Duty'}
                            </span>
                        </div>
                    </div>

                    {/* Time Information */}
                    <div className="space-y-4">
                        {(checkInTime || checkOutTime) && (
                            <div className="bg-slate-50 rounded-xl p-5 border border-slate-200">
                                <div className="flex items-center gap-2.5 text-slate-700 mb-2">
                                    <Clock className="w-4 h-4" />
                                    <span className="text-sm font-medium">
                                        {isCheckedIn ? 'Check-in Time' : 'Check-out Time'}
                                    </span>
                                </div>
                                <div className="text-slate-900 text-xl font-semibold pl-6">
                                    {isCheckedIn
                                        ? checkInTime?.toLocaleTimeString('en-US', {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: true
                                        })
                                        : checkOutTime?.toLocaleTimeString('en-US', {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: true
                                        })
                                    }
                                </div>
                            </div>
                        )}

                        {/* Duration */}
                        {isCheckedIn && elapsedTime && (
                            <div className="bg-slate-50 rounded-xl p-5 border border-slate-200">
                                <div className="flex items-center gap-2.5 text-slate-700 mb-2">
                                    <Calendar className="w-4 h-4" />
                                    <span className="text-sm font-medium">Time Elapsed</span>
                                </div>
                                <div className="text-slate-900 text-xl font-semibold pl-6 font-mono">
                                    {elapsedTime}
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Action Button */}
                    <button
                        onClick={handleAttendance}
                        className={`
                            w-full py-3.5 rounded-xl font-medium text-sm
                            transition-all duration-300 text-white
                            ${isCheckedIn
                                ? 'bg-red-500 hover:bg-red-700'
                                : 'bg-brand-500 hover:bg-brand-700'
                            }
                            focus:outline-none focus:ring-2 focus:ring-offset-2
                            ${isCheckedIn ? 'focus:ring-red-500' : 'focus:ring-blue-500'}
                            shadow-sm
                            active:scale-[0.98] transform
                        `}
                    >
                        {isCheckedIn ? 'Check Out' : 'Check In'}
                    </button>
                </div>
            </div>
        </div>
    );
};

const ModernAttendanceDisplay = ({ student, presentPercentage = 75, absentPercentage = 25 }) => {
    const [progress, setProgress] = useState(0);
    
    useEffect(() => {
      setTimeout(() => setProgress(presentPercentage), 100);
    }, [presentPercentage]);
  
    const getStatusColor = (percentage) => {
      if (percentage >= 90) return 'text-green-500';
      if (percentage >= 75) return 'text-blue-500';
      if (percentage >= 60) return 'text-yellow-500';
      return 'text-red-500';
    };
  
    return (
      <div className="bg-white rounded-xl shadow-lg p-6 max-w-md mx-2">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-bold text-gray-800">Attendance Overview</h2>
          <Calendar className="w-6 h-6 text-gray-500" />
        </div>
  
        {/* Main Stats Display */}
        <div className="flex justify-between items-center mb-8">
          <div className="relative">
            {/* Radial Progress */}
            <div className="w-32 h-32 rounded-full flex items-center justify-center bg-gray-50">
              <svg className="w-32 h-32 transform -rotate-90">
                <circle
                  cx="64"
                  cy="64"
                  r="54"
                  className="stroke-gray-200"
                  strokeWidth="8"
                  fill="none"
                />
                <circle
                  cx="64"
                  cy="64"
                  r="54"
                  className="stroke-blue-500 transition-all duration-1000 ease-out"
                  strokeWidth="8"
                  fill="none"
                  strokeLinecap="round"
                  style={{
                    strokeDasharray: '339.292',
                    strokeDashoffset: 339.292 * (1 - progress / 100),
                  }}
                />
              </svg>
              <div className="absolute inset-0 flex flex-col items-center justify-center">
                <span className={`text-3xl font-bold ${getStatusColor(progress)}`}>
                  {progress}%
                </span>
                <span className="text-sm text-gray-500">Present</span>
              </div>
            </div>
          </div>
  
          {/* Stats Cards */}
          <div className="space-y-4">
            <div className="bg-green-50 p-4 rounded-lg">
              <div className="flex items-center">
                <ArrowUp className="w-4 h-4 text-green-500 mr-2" />
                <span className="text-sm text-green-700">Present Days</span>
              </div>
              <div className="text-2xl font-bold text-green-700 mt-1">{presentPercentage}%</div>
            </div>
            
            <div className="bg-red-50 p-4 rounded-lg">
              <div className="flex items-center">
                <ArrowDown className="w-4 h-4 text-red-500 mr-2" />
                <span className="text-sm text-red-700">Absent Days</span>
              </div>
              <div className="text-2xl font-bold text-red-700 mt-1">{absentPercentage}%</div>
            </div>
          </div>
        </div>
  
        {/* Recent Status */}
        <div className="border-t pt-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Clock className="w-4 h-4 text-gray-500 mr-2" />
              <span className="text-sm text-gray-600"> Status </span>
            </div>
            <span className={`px-3 py-1 rounded-full text-sm font-medium ${
              progress >= 75 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
            }`}>
              {progress >= 75 ? 'Good' : 'Poor'}
            </span>
          </div>
        </div>
      </div>
    );
  };


export default Home