import { React, useState, useEffect } from 'react'
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux'
import { getTaskLogs, addTaskLog, updateTaskLog, deleteTaskLog } from '../../../../redux/actions/admin/tasklog';
import { getTasks } from '../../../../redux/actions/admin/task';
import toast, { Toaster } from 'react-hot-toast';
import Paginate from 'components/paginate';
import { useNavigate, useParams } from 'react-router-dom';
import {
    FiSearch,
    FiEdit2,
    FiTrash,
    FiChevronLeft,
    FiChevronRight,
    FiCalendar
} from "react-icons/fi";
import {
    FaEye
} from "react-icons/fa";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../../components/shadcn/table";
import DropdownSelect from 'components/select';
import DialogCustom from "../../../../components/dialog";

export const TaskLog = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const tasklogs = useSelector((state) => state.tasklog?.value?.data || { rows: [], count: 0 });
    const tasks = useSelector((state) => state.task?.value || { data: { rows: [] } });
    const classes = useSelector((state) => state.classRoom?.value || { data: { rows: [] } });
    const taskId = window.location.href.split("taskId=")[1];
    console.log(taskId, 'taskId')
    const [formData, setFormData] = useState({});
    const [filter, setFilter] = useState({
        name: "",
        status: "All",
        limit: 10,
        page: 1,
        date: new Date(),
        taskId: taskId ? taskId : null
    });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const notifySuccess = (message) => toast.success(message, {
        style: {
            padding: '35px',
            color: '#a0ca00',
        },
        duration: 3000,
        iconTheme: {
            primary: '#a0ca00',
            secondary: '#222c25',
        }
    })
    const notifyError = (message) => toast.error(message, {
        style: {
            border: '1px solid #fff',
            padding: '35px',
            color: 'red',
        },
        iconTheme: {
            primary: 'red',
            secondary: '#fff',
        }
    })

    useEffect(() => {
        dispatch(getTaskLogs(filter))
        dispatch(getTasks({ limit: 100 }))
    }, [dispatch])


    const handleActionButtonClick = (tasklogId, action) => {
        if (action === 'edit') setIsDialogOpen(true);
        if (action === 'delete') setIsDeleteDialogOpen(true);
        if (action === 'add') {
            navigate('/admin/tasklog/add');
        }
    };

    useEffect(() => {
        const formattedDate = moment(filter.date).format('YYYY-MM-DD');
        dispatch(getTaskLogs({ ...filter, date: formattedDate }));
    }, [filter]);

    const handleCrudTaskLog = async () => {
        try {
            setIsDialogOpen(false)
            if (formData.id) await dispatch(updateTaskLog(formData));
            if (!formData.id) await dispatch(addTaskLog(formData));
            notifySuccess("Success")

            setFormData({});
            await dispatch(getTaskLogs({}));

        } catch (error) {
            console.error('Failed to add state:', error);
        }
    };

    const handleDelete = async () => {
        try {
            setIsDeleteDialogOpen(false)
            await dispatch(deleteTaskLog(formData.id));
            // setFormData({});
            notifySuccess("Delete tasklog successfully")
            dispatch(getTaskLogs(filter));

        } catch (error) {
            console.error('Failed to add state:', error);
        }
    };

    const fields = [
        {
            id: "name",
            name: "name",
            label: "TaskLog Name",
            placeholder: "Enter TaskLog's Name",
            type: "text",
        },
        {
            id: "classId",
            name: "classId",
            label: "Class",
            placeHolder: "Select Class",
            type: "dropdown",
            list: classes.data.rows,
            toSelect: "id",
            selectedvalue: "class",
        }
    ];



    const handlePrevDay = () => {
        setFilter(prev => ({
            ...prev,
            date: moment(prev.date).subtract(1, 'days').toDate()
        }));
    };

    const handleNextDay = () => {
        const nextDay = moment(filter.date).add(1, 'days');
        if (nextDay.isSameOrBefore(moment(), 'day')) {
            setFilter(prev => ({
                ...prev,
                date: nextDay.toDate()
            }));
        }
    };
    return (
        <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4 dark:bg-navy-700">
            <Toaster />
            <div className="h-full w-full rounded-xl">
                <div className="flex justify-center mb-6">

                    <div className="flex items-center gap-4 bg-gray-50 p-2 rounded-xl shadow-sm">
                        <button
                            onClick={handlePrevDay}
                            className="flex items-center justify-center w-10 h-10 rounded-lg bg-white shadow-sm hover:bg-brand-50 transition-colors duration-200 text-gray-600 hover:text-brand-500"
                        >
                            <FiChevronLeft className="w-6 h-6" />
                        </button>

                        <div className="flex items-center gap-3 bg-white px-6 py-3 rounded-lg shadow-sm min-w-[200px] justify-center">
                            <FiCalendar className="w-5 h-5 text-brand-500" />
                            <span className="text-gray-700 font-medium text-lg">
                                {moment(filter.date).format('DD MMM YYYY')}
                            </span>
                        </div>

                        <button
                            onClick={handleNextDay}
                            className={`flex items-center justify-center w-10 h-10 rounded-lg shadow-sm transition-colors duration-200 ${moment(filter.date).isSame(moment(), 'day')
                                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                : 'bg-white hover:bg-brand-50 text-gray-600 hover:text-brand-500'
                                }`}
                            disabled={moment(filter.date).isSame(moment(), 'day')}
                        >
                            <FiChevronRight className="w-6 h-6" />
                        </button>
                    </div>
                </div>

                <div className='mt-3 p-4 rounded-xl border-solid border-2 border-gray-100'>
                    <Table className="border-b border-grey-500">
                        <TableCaption className="mb-3 font-bold text-2xl text-start text-black">
                            <div className='flex justify-between items-center'>
                            
                                <div className='flex justify-between w-1/2 items-center'>
                                    <div className="mr-5 p-4 flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white ">
                                        <p className="pl-3 pr-2 text-xl">
                                            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                                        </p>
                                        <input
                                            // value={filter.name}
                                            onChange={(e) => {
                                                setFilter({ ...filter, ["name"]: e.target.value })
                                            }}
                                            type="text"
                                            placeholder="Search..."
                                            className="block h-full w-full  bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                                        />
                                    </div>
                                    
                                </div>
                                <DropdownSelect
                                        list={tasks?.data?.rows?.map(task => ({ name: task.task, id: task.id }))}
                                        triggerStyles="bg-lightPrimary !rounded-full !w-[200px] !mr-4"
                                        placeHolder={ taskId ? tasks?.data?.rows?.find(task => task.id === taskId)?.task : "Select Task" }
                                        toSelect="id"
                                        onChange={(value) => setFilter({ ...filter, taskId: value })}
                                    />
                                {/* <div className='flex  justify-end'>
                                    <button
                                        onClick={() => handleActionButtonClick(null, "add")}
                                        className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                        Add TaskLog
                                    </button>

                                </div> */}
                            </div>
                        </TableCaption>
                        {tasklogs?.rows?.length > 0 ? (
                            <div className="">
                                <TableHeader>

                                    <TableRow className="dark:text-white ">
                                        <TableHead className="w-3/12"> Student </TableHead>
                                        <TableHead className="w-4/12"> Task </TableHead>
                                        <TableHead className="w-4/12"> Status </TableHead>
                                        <TableHead className=""> Completed Time </TableHead>
                                        {/* <TableHead className="w-1/12 text-center">Actions</TableHead> */}
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {tasklogs.rows.map((tasklog) => (
                                        <TableRow key={tasklog.id}>
                                            <TableCell className="font-medium">
                                                <h4 className=''>{tasklog?.student?.admission?.name}</h4>
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <h4 className=''>{tasklog?.task?.task}</h4>
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <h4 className=''>{tasklog?.done ? "Completed" : "In-Complete"}</h4>
                                            </TableCell>

                                            <TableCell className="font-medium">
                                                <h4 className=''>{moment(tasklog?.doneDate).format("hh:mm A")}</h4>
                                            </TableCell>
                                        </TableRow>
                                    ))}


                                </TableBody>
                                <Paginate
                                    page={filter.page}
                                    totalCount={tasklogs.count || 0}
                                    limit={filter.limit}
                                    changePage={(value) => {
                                        setFilter({ ...filter, ["page"]: value })
                                    }}
                                />
                            </div>
                        ) : (
                            <div className='p-10 flex flex-col justify-center items-center'>
                                <p className='mb-5 font-semibold'> No TaskLogs Found! </p>
                                {/* <button
                                    onClick={() => handleActionButtonClick(null, "add")}
                                    className="border-2 text-white linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500 active:!bg-white/70">
                                    Add TaskLog
                                </button> */}
                            </div>
                        )}
                    </Table>
                </div>
            </div>

            <DialogCustom

                open={isDeleteDialogOpen}
                onOpenChange={setIsDeleteDialogOpen}
                dialogTitle="Delete TaskLog"
                dialogWidth="w-1/2"
                dialogDesc="Are you sure you want to delete this tasklog ?"
                content={
                    <div className='flex flex-row-reverse'>
                        <button
                            onClick={handleDelete}
                            className=" text-white linear rounded-xl border-2 border-white bg-red-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-red-500 hover:border-2 hover:border-red-700 hover:shadow-lg  ">
                            Delete
                        </button>
                        <button
                            onClick={() => {
                                setIsDeleteDialogOpen(false);
                            }}
                            className="mr-4 text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
                            Cancel
                        </button>
                    </div>
                }
            />
        </div>
    )
};

const styles = `
    .date-selector-container {
        background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1), rgba(255,255,255,0));
    }

    .date-nav-button {
        transition: all 0.2s ease-in-out;
    }

    .date-nav-button:hover:not(:disabled) {
        transform: translateY(-1px);
    }

    .date-nav-button:active:not(:disabled) {
        transform: translateY(1px);
    }
`;

const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default TaskLog