import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getFees, addFee, updateFee, deleteFee, sendFeesDueNotification } from '../../../redux/actions/admin/fees';
import { getFeesMasters } from '../../../redux/actions/admin/feesmaster';

import toast, { Toaster } from 'react-hot-toast';
import DropdownSelect from 'components/select';
import Paginate from 'components/paginate';
import { useNavigate } from 'react-router-dom';
import {
    FiSearch,
    FiEdit2,
    FiTrash,
    FiEye,
    FiInfo
} from "react-icons/fi";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../components/shadcn/table";

import DialogCustom from "../../../components/dialog";
import Form from "../../../components/form";

export const Fee = (props) => {
    const [formData, setFormData] = useState({});
    const [filter, setFilter] = useState({
        name: "",
        status: "All",
        limit: 10,
        page: 1
    });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const notifySuccess = (message) => toast.success(message, {
        style: {
            padding: '35px',
            color: '#a0ca00',
        },
        duration: 3000,
        iconTheme: {
            primary: '#a0ca00',
            secondary: '#222c25',
        }
    })
    const notifyError = (message) => toast.error(message, {
        style: {
            border: '1px solid #fff',
            padding: '35px',
            color: 'red',
        },
        iconTheme: {
            primary: 'red',
            secondary: '#fff',
        }
    })
    let feesmasters = useSelector((feesmasters) => feesmasters.feesmaster.value.data);

    feesmasters = feesmasters.rows.map(item => ({
        ...item,
        name: item.course?.name + " " + item.period + " fees - Rs." + item.amount || null // Add `name` key from `course.name`, fallback to `null` if course is missing
    }));

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFees(filter))
        dispatch(getFeesMasters({ limit: 25 }))

        // setFormData({})
    }, [dispatch])


    const navigate = useNavigate()
    const fees = useSelector((fees) => fees.fees.value.data);
    const handleActionButtonClick = (feesId, action) => {
        if(action === 'due') {
            dispatch(sendFeesDueNotification({feesId}))
            notifySuccess("Notification sent successfully")
            return
        }
        if (action === 'edit') setIsDialogOpen(true);
        if (action === 'delete') setIsDeleteDialogOpen(true);
        if (feesId != null) {
            let selectedFee = fees.rows.find((fees) => fees.id === feesId);
            if (selectedFee) {
                // Create a deep copy of selectedFee
                const updatedFee = {
                    ...selectedFee,
                    feesmaster: { ...selectedFee.feesmaster }, // Ensure feesmaster is a new object
                };

                // Update the name property
                updatedFee.feesmaster.name = `${selectedFee.feesmaster.course?.name || ''} ${selectedFee.feesmaster.period || ''} fees - Rs. ${selectedFee.feesmaster.amount || 0}`;

                setFormData(updatedFee);
                console.log(updatedFee, "updatedFee");
            }
        } else {
            setIsDialogOpen(true);
            setFormData({});
        }
    };




    useEffect(() => {
        dispatch(getFees(filter));
    }, [filter]);

    const handleCrudFee = async () => {
        try {
            setIsDialogOpen(false)
            if (formData.id) await dispatch(updateFee(formData));
            if (!formData.id) await dispatch(addFee(formData));
            notifySuccess("Success")

            setFormData({});
            await dispatch(getFees({}));

        } catch (error) {
            console.error('Failed to add state:', error);
        }
    };

    const handleDelete = async () => {
        try {
            setIsDeleteDialogOpen(false)
            await dispatch(deleteFee(formData.id));
            // setFormData({});
            notifySuccess("Delete fees successfully")
            await dispatch(getFees({}));

        } catch (error) {
            console.error('Failed to add state:', error);
        }
    };

    // const fees = [];
    const fields = [
        {
            id: "feesmasterId",
            name: "feesmasterId",
            label: "Fees Master",
            placeHolder: feesmasters?.length ? "Select FeesMaster" : "No FeesMaster available",
            type: "dropdown",
            list: feesmasters,
            toSelect: "id",
            selectedvalue: "feesmaster",
            required: true,
            disabled: !feesmasters?.length
        },
        {
            id: "period",
            name: "period",
            label: "Month",
            placeHolder: "Select Month",
            type: "dropdown",
            list: Array.from({ length: 12 }, (_, index) => {
                const date = new Date(2024, index, 1); // Create a date for each month
                return {
                    id: index + 1, // Month ID (1-12)
                    name: date.toLocaleString('default', { month: 'long' }) // Month name
                };
            }),
            toSelect: "name",
            selectedvalue: "period",
            required: true
        },
    ];

    return (
        <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4 dark:bg-navy-700">
            <Toaster />
            <div className="h-full w-full rounded-xl">
                <div className='mt-3  p-4 rounded-xl border-solid border-2 border-gray-100'>
                    <Table className="border-b border-grey-500">
                        <TableCaption className="mb-3 font-bold  text-2xl text-start text-black">
                            <div className='flex justify-between items-center'>
                                <div className='flex justify-between w-1/2 items-center'>
                                    <div className="mr-5 p-4 flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white ">
                                        <p className="pl-3 pr-2 text-xl">
                                            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                                        </p>
                                        <input
                                            // value={filter.name}
                                            onChange={(e) => {
                                                setFilter({ ...filter, ["name"]: e.target.value })
                                            }}
                                            type="text"
                                            placeholder="Search..."
                                            className="block h-full w-full  bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                                        />
                                    </div>
                                    {/* <DropdownSelect
                                                list={[
                                                    { name: "All", id: 1 },
                                                    { name: "Active", id: 2 },
                                                    { name: "Inactive", id: 3 },
                                                ]}
                                                triggerStyles="bg-lightPrimary !rounded-full"
                                                // listStyles="text-sm text-red-700"
                                                placeHolder={"Status"}
                                                toSelect={"name"}
                                                onChange={(value) => {
                                                    setFilter({ ...filter, ["status"]: value })
                                                }}
                                            /> */}
                                </div>
                                <div className='flex  justify-end'>
                                    <button
                                        onClick={() => handleActionButtonClick(null, "add")}
                                        className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                        Add Fee
                                    </button>

                                </div>
                            </div>
                        </TableCaption>
                        {fees.rows?.length > 0 &&
                            <div className="">
                                <TableHeader>

                                    <TableRow className="dark:text-white">
                                        <TableHead className="w-8/12"> Fees </TableHead>
                                        <TableHead className="w-4/12"> Stats </TableHead>
                                        <TableHead className="w-2/12 text-center ">Actions</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {fees.rows.map((fee) => (
                                        <TableRow key={fee.id}>
                                            <TableCell className="font-medium">
                                                <h4 className=''> {fee?.period + " - " + fee?.feesmaster?.course?.name + " fees - Rs." + fee?.feesmaster?.amount} </h4>
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <div className="flex gap-2 items-center">
                                                    <div className="flex flex-col gap-1">
                                                        <span className="text-green-500">Paid: {fee?.feeslogs?.filter(a => a.paid === true)?.length || 0}</span>
                                                        <span className="text-red-500">Unpaid: {fee?.feeslogs?.filter(a => a.paid === false)?.length || 0}</span>
                                                    </div>
                                                    {fee?.feeslogs?.length > 0 && (
                                                        <div className="relative w-12 h-12 ml-2">
                                                            <svg className="w-full h-full transform -rotate-90">
                                                                <circle
                                                                    cx="24"
                                                                    cy="24"
                                                                    r="20"
                                                                    fill="transparent"
                                                                    stroke="#f3f4f6"
                                                                    strokeWidth="4"
                                                                />
                                                                <circle
                                                                    cx="24"
                                                                    cy="24"
                                                                    r="20"
                                                                    fill="transparent"
                                                                    stroke="#22c55e"
                                                                    strokeWidth="4"
                                                                    strokeDasharray={`${(fee?.feeslogs?.filter(a => a.paid === true)?.length / fee?.feeslogs?.length) * 125.6} 125.6`}
                                                                />
                                                            </svg>
                                                            <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs font-semibold">
                                                                {Math.round((fee?.feeslogs?.filter(a => a.paid === true)?.length / fee?.feeslogs?.length) * 100)}%
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </TableCell>
                                            <TableCell className="w-1/6  ">
                                                <div className='w-full flex justify-around'>
                                                    <div
                                                        title='Notify Fees Due'
                                                        onClick={() => handleActionButtonClick(fee.id, "due")}
                                                        className='mx-2 bg-green-500 w-fit p-1 rounded-3xl cursor-pointer' >
                                                        <FiInfo className='h-4 w-4 text-white' />
                                                    </div>
                                                    <div
                                                        onClick={() => handleActionButtonClick(fee.id, "edit")}
                                                        className='mx-2 bg-yellow-500 w-fit p-1 rounded-3xl cursor-pointer' >
                                                        <FiEdit2 className='h-4 w-4 text-white' />
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            navigate(`/admin/fees/entries/${fee.id}`);
                                                        }}
                                                        className='mx-2 bg-blue-500 w-fit p-1 rounded-3xl cursor-pointer' >
                                                        <FiEye className='h-4 w-4 text-white' />
                                                    </div>
                                                    <div
                                                        onClick={() => handleActionButtonClick(fee.id, "delete")}
                                                        className='mx-2 bg-red-500 w-fit p-1 rounded-3xl cursor-pointer'>
                                                        <FiTrash className='h-4 w-4 text-white' />
                                                    </div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}


                                </TableBody>
                                <Paginate
                                    page={filter.page}
                                    totalCount={fees.count}
                                    limit={filter.limit}
                                    changePage={(value) => {
                                        setFilter({ ...filter, ["page"]: value })
                                    }}
                                />
                            </div>
                        }
                    </Table>
                    {fees.rows?.length == 0 &&
                        <div className='p-10 flex flex-col justify-center items-center'>
                            <p className='mb-5 font-semibold'> No Fees Found! </p>
                            <button
                                onClick={() => handleActionButtonClick(null, "add")}
                                className="border-2 text-white linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                Add Fee
                            </button>
                        </div>
                    }

                    <DialogCustom

                        open={isDialogOpen}
                        onOpenChange={setIsDialogOpen}
                        dialogTitle={formData.id ? "Edit Fee" : "Add Fee"}
                        dialogWidth="w-1/2"
                        dialogDesc={(formData.id ? "Update" : "Add") + " the necessary fields and save."}

                        content={
                            <>
                                <Form
                                    fields={fields}
                                    formData={formData}
                                    onChange={(newFormData) => setFormData(newFormData)}
                                />
                                <div className='flex justify-end'>
                                    <button
                                        onClick={handleCrudFee}
                                        className="text-black linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-brand-850/80 hover:text-white  active:!bg-white/70">
                                        {formData.id ? "Update" : "Add" + " Fee"}
                                    </button>
                                </div></>
                        }
                    />
                    <DialogCustom

                        open={isDeleteDialogOpen}
                        onOpenChange={setIsDeleteDialogOpen}
                        dialogTitle="Delete Fee"
                        dialogWidth="w-1/2"
                        dialogDesc="Are you sure you want to delete this fees ?"
                        content={
                            <div className='flex flex-row-reverse'>
                                <button
                                    onClick={handleDelete}
                                    className=" text-white linear rounded-xl border-2 border-white bg-red-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-red-500 hover:border-2 hover:border-red-700 hover:shadow-lg  ">
                                    Delete
                                </button>
                                <button
                                    onClick={() => {
                                        setIsDeleteDialogOpen(false);
                                    }}
                                    className="mr-4 text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
                                    Cancel
                                </button>
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    )
};

export default Fee