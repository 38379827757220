import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCourses } from "../../redux/actions/admin/course";
import { crudLessonLog } from "../../redux/actions/admin/lesson";
import { ChevronDown, ChevronRight, FileText, Link, Clock, CheckCircle } from "lucide-react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const ConfirmationDialog = ({ isOpen, onConfirm, onCancel, materialName }) => {
    if (!isOpen) return null;

    return (
        <>
            <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center z-50">
                <div className="bg-white rounded-xl p-6 max-w-md w-full mx-4 shadow-xl transform transition-all duration-300">
                    <div className="mb-6">
                        <div className="p-4 bg-gray-50 rounded-lg">
                            <p className="text-gray-700">
                                Are you sure you want to mark "{materialName}" as complete?
                            </p>
                        </div>
                    </div>
                    <div className="flex gap-4">
                        <button
                            onClick={onCancel}
                            className="flex-1 px-4 py-2 border border-gray-200 text-gray-700 rounded-lg hover:bg-gray-50 transition-all duration-300"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={onConfirm}
                            className="flex-1 px-4 py-2 bg-brand-500 text-white rounded-lg hover:bg-brand-600 transition-all duration-300"
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    }).format(date);
};

const CourseTree = () => {
    const dispatch = useDispatch();
    const [courses, setCourses] = useState([]);
    const [expandedCourses, setExpandedCourses] = useState(new Set());
    const [expandedSubjects, setExpandedSubjects] = useState(new Set());
    const [expandedLessons, setExpandedLessons] = useState(new Set());
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        materialId: null,
        materialName: "",
        courseId: null,
        subjectId: null,
        lessonId: null
    });

    useEffect(() => {
        dispatch(getCourses({ userId: true })).then((data) => {
            setCourses(data.payload.rows || []);
        });
    }, [dispatch]);

    const toggleNode = (type, id) => {
        const setters = {
            course: setExpandedCourses,
            subject: setExpandedSubjects,
            lesson: setExpandedLessons
        };

        setters[type](prev => {
            const newSet = new Set(prev);
            if (newSet.has(id)) {
                newSet.delete(id);
            } else {
                newSet.add(id);
            }
            return newSet;
        });
    };

    const handleStudyMaterialCheck = (courseId, subjectId, lessonId, material) => {
        setConfirmDialog({
            isOpen: true,
            materialId: material.id,
            materialName: material.name,
            courseId,
            subjectId,
            lessonId
        });
    };

    const handleConfirm = () => {
        const { courseId, subjectId, lessonId, materialId } = confirmDialog;
        dispatch(crudLessonLog({
            courseId,
            subjectId,
            lessonId,
            materialId
        }))
            .then(() => {
                toast.success('Lesson marked successfully');
                dispatch(getCourses({ userId: true })).then((data) => {
                    setCourses(data.payload.rows || []);
                });
                //   navigate('/parent/lessons');
            });
        setConfirmDialog({ isOpen: false });
    };

    if (!courses.length) {
        return (
            <div className="bg-lightPrimary min-h-screen p-4">
                <div className="bg-white rounded-xl p-4 shadow-lg">
                    <div className="flex items-center justify-center space-x-2">
                        <div className="w-2 h-2 rounded-full animate-bounce bg-brand-500 delay-75"></div>
                        <div className="w-2 h-2 rounded-full animate-bounce bg-brand-500 delay-150"></div>
                        <div className="w-2 h-2 rounded-full animate-bounce bg-brand-500 delay-300"></div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={`bg-lightPrimary min-h-screen p-4 ${confirmDialog.isOpen ? 'overflow-hidden' : ''}`}>
            <div className={`bg-white rounded-xl p-4 shadow-lg transform transition-all duration-300 hover:shadow-xl ${confirmDialog.isOpen ? 'blur-sm' : ''}`}>
                {courses.map((course) => (
                    <div key={course.id} className="mb-6">
                        {/* Course level structure remains the same */}
                        <div
                            onClick={() => toggleNode('course', course.id)}
                            className="flex items-center p-4 bg-gray-100 rounded-t-lg cursor-pointer hover:bg-gray-100 transition-all duration-300 ease-in-out"
                        >
                            <div className="transform transition-transform duration-300">
                                {expandedCourses.has(course.id) ? (
                                    <ChevronDown className="text-brand-500 mr-2" />
                                ) : (
                                    <ChevronRight className="text-brand-500 mr-2" />
                                )}
                            </div>
                            <span className="font-medium text-lg">{course.name}</span>
                        </div>

                        <div className={`transition-all duration-300 ease-in-out overflow-hidden ${expandedCourses.has(course.id) ? 'max-h-[2000px] opacity-100' : 'max-h-0 opacity-0'}`}>
                            {course.subjects?.map((subject) => (
                                <div key={subject.id} className="ml-4 mt-2">
                                    {/* Subject level structure remains the same */}
                                    <div
                                        onClick={() => toggleNode('subject', subject.id)}
                                        className="flex items-center p-3 rounded-md cursor-pointer hover:bg-gray-50 transition-all duration-300"
                                    >
                                        <div className="transform transition-transform duration-300">
                                            {expandedSubjects.has(subject.id) ? (
                                                <ChevronDown className="text-gray-500 mr-2" />
                                            ) : (
                                                <ChevronRight className="text-gray-500 mr-2" />
                                            )}
                                        </div>
                                        <FileText className="text-gray-500 mr-3" />
                                        <span className="text-gray-700">{subject.name}</span>
                                        {subject.lessons?.length > 0 && (
                                            <span className="ml-2 text-sm text-gray-500">
                                                ({subject.lessons.length} {subject.lessons.length === 1 ? 'lesson' : 'lessons'})
                                            </span>
                                        )}
                                    </div>

                                    <div className={`transition-all duration-300 ease-in-out overflow-hidden ${expandedSubjects.has(subject.id) ? 'max-h-[2000px] opacity-100' : 'max-h-0 opacity-0'}`}>
                                        {subject.lessons?.map((lesson) => (
                                            <div key={lesson.id} className="ml-6 mt-2">
                                                {/* Lesson level structure remains the same */}
                                                <div
                                                    onClick={() => toggleNode('lesson', lesson.id)}
                                                    className="flex items-center p-3 rounded-md cursor-pointer hover:bg-gray-50 transition-all duration-300"
                                                >
                                                    <div className="transform transition-transform duration-300">
                                                        {expandedLessons.has(lesson.id) ? (
                                                            <ChevronDown className="text-gray-500 mr-2" />
                                                        ) : (
                                                            <ChevronRight className="text-gray-500 mr-2" />
                                                        )}
                                                    </div>
                                                    <FileText className="text-gray-500 mr-3" />
                                                    <div className="flex-1">
                                                        <span className="text-gray-700">{lesson.name}</span>
                                                        {lesson.completeWithin && (
                                                            <div className="flex items-center text-xs text-gray-500 mt-1">
                                                                <Clock className="h-3 w-3 mr-1 animate-pulse" />
                                                                <span>Complete within {lesson.completeWithin}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className={`transition-all duration-300 ease-in-out overflow-hidden ${expandedLessons.has(lesson.id) ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
                                                    <div className="ml-8 space-y-2">
                                                        {lesson.studymaterials?.map((material) => (
                                                            <div
                                                                key={material.id}
                                                                className="p-3 rounded-md hover:bg-gray-50 transition-all duration-300"
                                                            >
                                                                <div className="flex items-center">
                                                                    <Link className="h-4 w-4 text-gray-400 mr-2" />
                                                                    <span className="text-gray-600">
                                                                        {material.name}
                                                                        {/* <span className={`ml-2 text-xs px-2 py-1 rounded-full ${material.lessonlogs?.length ? "bg-brand-500" : "bg-red-500"} text-white`}>
                                      {material.lessonlogs?.length ? "Completed" : "Pending"}
                                    </span> */}
                                                                    </span>
                                                                </div>
                                                                {material.lessonlogs?.length > 0 ? (
                                                                    <div className="ml-6 mt-2 flex items-center text-xs text-gray-500">
                                                                        <CheckCircle className="h-3 w-3 mr-1 text-brand-500" />
                                                                        <span>Completed on {formatDate(material.lessonlogs[0].completedDate)}</span>
                                                                    </div>
                                                                ) : (
                                                                    <div className="ml-6 mt-2">
                                                                        <button
                                                                            onClick={() => handleStudyMaterialCheck(
                                                                                course.id,
                                                                                subject.id,
                                                                                lesson.id,
                                                                                material
                                                                            )}
                                                                            className="text-sm text-brand-500 hover:underline transition-colors duration-300"
                                                                        >
                                                                            Mark as Complete
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            <ConfirmationDialog
                isOpen={confirmDialog.isOpen}
                materialName={confirmDialog.materialName}
                onConfirm={handleConfirm}
                onCancel={() => setConfirmDialog({ isOpen: false })}
            />
        </div>
    );
};

export default CourseTree;