
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const getLessons = createAsyncThunk("lesson/get", async (query) => {
  console.log(query)

  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
);
let params = new URLSearchParams(filteredQuery);

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/lesson?${params.toString()}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const addLesson = createAsyncThunk("lesson/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/lesson/log`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});

export const crudLessonLog = createAsyncThunk("lesson/addlog", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/lesson/log`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});



export const updateLesson = createAsyncThunk("lesson/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/lesson/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});


export const deleteLesson = createAsyncThunk("lesson/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/lesson/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const lessonsSlice = createSlice({
  name: 'lessones',
  initialState: {
  
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        rows: []
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLessons.fulfilled, (lesson, action) => {
     
        lesson.value.data.rows = action.payload.rows
        lesson.value.data.count = action.payload.count
        lesson.value.status = action.payload.message
        console.log('Updated lesson:', lesson.value.data.rows);

        
      })
      .addCase(getLessons.pending, (lesson) => {
        lesson.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getLessons.rejected, (lesson) => {
        lesson.status = "Failed to  fetch data..."
      })
      .addCase(updateLesson.fulfilled, (lesson, action) => {
        console.log(action.payload.message, "action.payload.message")
        lesson.value.status = action.payload.message
        // find the index of the lesson with the updated id
        // const index = lesson.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the lesson at the found index with the updated lesson
        //   lesson.value.data[index] = action.payload;
        // }
      })
      .addCase(updateLesson.pending, (lesson) => {
        lesson.status = "Updating lesson. Please wait a moment...";
      })
      .addCase(updateLesson.rejected, (lesson) => {
        lesson.status = "Failed to update lesson...";
      })
      .addCase(deleteLesson.fulfilled, (lesson, action) => {
        // // filter out the lesson with the deleted id
        // lesson.value.data = lesson.value.data.filter(item => item.id !== action.payload.id);
        lesson.value.status = action.payload.message
      })
      .addCase(deleteLesson.pending, (lesson) => {
        lesson.status = "Deleting lesson. Please wait a moment...";
      })
      .addCase(deleteLesson.rejected, (lesson) => {
        lesson.status = "Failed to delete lesson...";
      })
      .addCase(addLesson.fulfilled, (lesson, action) => {
        // lesson.value.data.push(action.payload); // add the new lesson to the list
     })
     .addCase(addLesson.pending, (lesson) => {
        lesson.status = "Adding lesson. Please wait a moment...";
     })
     .addCase(addLesson.rejected, (lesson) => {
        lesson.status = "Failed to add lesson...";
     });
  }
})

export default lessonsSlice.reducer
