import React from 'react';
import Tabs from "../../../components/tabs";
import Students from './Students';
import Assign from './Assign';
import { useParams } from 'react-router-dom';
import { getClasses, getStudentsForClass } from '../../../redux/actions/admin/classRoom';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import Widget from "components/widget/Widget";
import { FiUser, FiUsers } from "react-icons/fi";


const Enrollment = ({ setTitle }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [classRoom, setClassRoom] = useState({});
    const [studentsCount, setStudentsCount] = useState(0);
    useEffect(() => {
        dispatch(getClasses({ id })).then((data) => {
            setClassRoom(data.payload.rows[0]);
            setTitle("Class / " + data?.payload?.rows[0]?.name)
        });
        dispatch(getStudentsForClass(id)).then((data) => {
            setStudentsCount(data.payload.length);
        });
    }, [dispatch]);
    return (
        <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4 dark:bg-navy-700">
            <div className="h-full w-full rounded-xl">
                <div className="my-4 grid grid-cols-2 gap-5 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 3xl:grid-cols-1">
                    <Widget
                        icon={<FiUser className="h-7 w-7" />}
                        title={"Ustaad"}
                        subtitle={classRoom?.user?.fullname}
                    />
                    <Widget
                        icon={<FiUsers className="h-7 w-7" />}
                        title={"Total Students"}
                        subtitle={studentsCount}
                    />
                </div>
                <Tabs
                    tabs={["Students", "Assign Students"]}
                    tabContents={[<Students />, <Assign />]}
                />
            </div>
        </div>
    );
};

export default Enrollment;
