import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEnrollments, bulkUpdateClass } from '../../../redux/actions/admin/enrollment';
import { getSessions } from '../../../redux/actions/admin/session';
import { getCourses } from '../../../redux/actions/admin/course';
import DropdownSelect from 'components/select';
import toast from 'react-hot-toast';
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../components/shadcn/table";
import { useParams } from 'react-router-dom';

const Assign = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [filter, setFilter] = useState({ sessionId: '', courseId: '', limit: 200 });
    const [selectedStudents, setSelectedStudents] = useState([]);
    const enrollments = useSelector((state) => state.enrollment.value.data);
    const sessions = useSelector((state) => state.session.value.data);
    const courses = useSelector((state) => state.course.value.data);

    useEffect(() => {
        dispatch(getSessions({ limit: 50 }));
        dispatch(getCourses({ limit: 50 }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(getEnrollments(filter));
    }, [dispatch, filter]);

    const handleAssign = () => {
        const enrollmentIds = selectedStudents.map(studentId =>
            enrollments.rows.find(enrollment => enrollment.studentId === studentId).id
        );
        // console.log('Enrollment IDs:', enrollmentIds);
        // console.log('Class ID:', id);
        dispatch(bulkUpdateClass( 
            { 
                enrollments: enrollmentIds,
                classId: id
             } 
        ))
        toast.success('Students assigned successfully');
        setSelectedStudents([]);
        setFilter({ sessionId: '', courseId: '' }); // Clear filters
    };

    return (
        <div>
            <Table>
                <TableCaption>
                    <div className="filters flex justify-between items-center">
                        <div className="flex items-center">
                            <DropdownSelect
                                list={sessions.rows.map(session => ({ name: session.name, id: session.id }))}
                                triggerStyles="bg-lightPrimary !rounded-full !w-[200px] !mr-4"
                                placeHolder="Select Session"
                                toSelect="id"
                                onChange={(value) => setFilter({ ...filter, sessionId: value })}
                            />
                            <DropdownSelect
                                list={courses.rows.map(course => ({ name: course.name, id: course.id }))}
                                triggerStyles="bg-lightPrimary !rounded-full !w-[200px] ml-4"
                                placeHolder="Select Course"
                                toSelect="id"
                                onChange={(value) => setFilter({ ...filter, courseId: value })}
                            />
                        </div>
                        <button
                            onClick={handleAssign}
                            className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500 active:!bg-white/70">
                            Assign Students
                        </button>
                    </div>
                    <div className="selected-students flex flex-wrap mt-4">
                        {selectedStudents.map((studentId) => {
                            const student = enrollments.rows.find(enrollment => enrollment.studentId === studentId);
                            return (
                                <span key={studentId} className="bg-gray-200 text-gray-700 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full">
                                    {student?.student?.admission?.name || 'N/A'}
                                </span>
                            );
                        })}
                    </div>
                </TableCaption>
                <TableHeader>
                    <TableRow>
                        <TableHead>Select</TableHead>
                        <TableHead>Student Name</TableHead>
                        <TableHead>Register Number</TableHead>
                        <TableHead>Course</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {enrollments.rows.map((enrollment) => (
                        <TableRow key={enrollment.id}>
                            <TableCell>
                                <input
                                    type="checkbox"
                                    checked={selectedStudents.includes(enrollment.studentId)}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedStudents([...selectedStudents, enrollment.studentId]);
                                        } else {
                                            setSelectedStudents(selectedStudents.filter(id => id !== enrollment.studentId));
                                        }
                                    }}
                                />
                            </TableCell>
                            <TableCell>{enrollment.student?.admission?.name || 'N/A'}</TableCell>
                            <TableCell>{enrollment.registerNumber || 'N/A'}</TableCell>
                            <TableCell>{enrollment.course?.name || 'N/A'}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default Assign;
