
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const getAttendances = createAsyncThunk("attendance/get", async (query) => {
  console.log(query)

  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
);
let params = new URLSearchParams(filteredQuery);

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/attendance?${params.toString()}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const addAttendance = createAsyncThunk("attendance/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/attendance/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});



export const updateAttendance = createAsyncThunk("attendance/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/attendance/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});


export const deleteAttendance = createAsyncThunk("attendance/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/attendance/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const attendancesSlice = createSlice({
  name: 'attendancees',
  initialState: {
  
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        rows: []
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAttendances.fulfilled, (attendance, action) => {
     
        attendance.value.data.rows = action.payload.rows
        attendance.value.data.count = action.payload.count
        attendance.value.status = action.payload.message
        console.log('Updated attendance:', attendance.value.data.rows);

        
      })
      .addCase(getAttendances.pending, (attendance) => {
        attendance.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getAttendances.rejected, (attendance) => {
        attendance.status = "Failed to  fetch data..."
      })
      .addCase(updateAttendance.fulfilled, (attendance, action) => {
        console.log(action.payload.message, "action.payload.message")
        attendance.value.status = action.payload.message
        // find the index of the attendance with the updated id
        // const index = attendance.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the attendance at the found index with the updated attendance
        //   attendance.value.data[index] = action.payload;
        // }
      })
      .addCase(updateAttendance.pending, (attendance) => {
        attendance.status = "Updating attendance. Please wait a moment...";
      })
      .addCase(updateAttendance.rejected, (attendance) => {
        attendance.status = "Failed to update attendance...";
      })
      .addCase(deleteAttendance.fulfilled, (attendance, action) => {
        // // filter out the attendance with the deleted id
        // attendance.value.data = attendance.value.data.filter(item => item.id !== action.payload.id);
        attendance.value.status = action.payload.message
      })
      .addCase(deleteAttendance.pending, (attendance) => {
        attendance.status = "Deleting attendance. Please wait a moment...";
      })
      .addCase(deleteAttendance.rejected, (attendance) => {
        attendance.status = "Failed to delete attendance...";
      })
      .addCase(addAttendance.fulfilled, (attendance, action) => {
        // attendance.value.data.push(action.payload); // add the new attendance to the list
     })
     .addCase(addAttendance.pending, (attendance) => {
        attendance.status = "Adding attendance. Please wait a moment...";
     })
     .addCase(addAttendance.rejected, (attendance) => {
        attendance.status = "Failed to add attendance...";
     });
  }
})

export default attendancesSlice.reducer
