import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEnrollments, removeStudentFromClass } from '../../../redux/actions/admin/enrollment';
import { useParams } from 'react-router-dom';
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../components/shadcn/table";

const Students = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const enrollments = useSelector((state) => state.enrollment.value.data);

    useEffect(() => {
        console.log(id, "classId")
        dispatch(getEnrollments({ limit: 100, classId: id }));
    }, [dispatch, id]);

    return (
        <div>
            {
                enrollments.rows.length === 0 &&
                <div className="text-center text-lg text-gray-500 py-4"> No students found </div>
            }
            {
                enrollments.rows.length > 0 &&
                <Table>
                    <TableCaption>Students</TableCaption>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Student Name</TableHead>
                            <TableHead>Register Number</TableHead>
                            <TableHead>Course</TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {enrollments.rows.map((enrollment) => (
                            <TableRow key={enrollment.id}>
                                <TableCell>{enrollment.student?.admission?.name || 'N/A'}</TableCell>
                                <TableCell>{enrollment.registerNumber || 'N/A'}</TableCell>
                                <TableCell>{enrollment.course?.name || 'N/A'}</TableCell>
                                <TableCell className="w-1/12">
                                    <button
                                        onClick={() => {
                                            dispatch(removeStudentFromClass({ id: enrollment.id }));
                                            dispatch(getEnrollments({ limit: 100, classId: id }));
                                        }}
                                        title='Remove Student From Class'
                                        className="border-2 text-white linear rounded-xl bg-red-500 px-2 py-1 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                        Remove
                                    </button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            }
        </div>
    );          <a href=""></a>
};

export default Students;
