import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IoCheckmarkCircle } from "react-icons/io5";
import { IoAdd } from "react-icons/io5";
import { fetchLessonLogs } from '../../redux/actions/home';

const StaffLessonLogs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    dispatch(fetchLessonLogs()).then((data) => {
      setLogs(data.payload);
    });
  }, [dispatch]);

  return (
    <div className="min-h-screen bg-lightPrimary p-4">
      <div className="max-w-4xl mx-auto">
        {/* Header Section with Title and Add Button */}
        <div className="flex justify-end mb-4">
          <button
            onClick={() => navigate('/parent/add-lesson-log')}
            className="flex items-center space-x-2 bg-brand-500 text-white px-4 py-2 rounded-lg hover:bg-brand-600 transition-colors duration-300"
          >
            <IoAdd className="text-xl" />
            <span>Add New Log</span>
          </button>
        </div>

        {/* Main Content */}
        <div className="bg-white rounded-2xl shadow-lg p-4 space-y-4">

          {/* Logs Display */}
          {logs?.length === 0 ? (
            <div className="flex flex-col items-center justify-center py-12">
              <div className="text-gray-400 text-lg mb-2">No lesson logs found</div>
              <p className="text-sm text-gray-500">Create a new log entry using the button above</p>
            </div>
          ) : (
            <div className="grid gap-4">
              {logs.map((log) => (
                <div
                  key={log.id}
                  onClick={ () => {
                    navigate(`/parent/add-lesson-log/${log.id}`)
                  }}
                  className="group relative bg-white border border-gray-200 hover:border-brand-500 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 p-4"
                >
                  <div className="flex items-center justify-between">
                    <div className="space-y-2">
                      <div className="flex items-center space-x-2">
                        <h2 className="text-lg font-semibold text-gray-800">
                          {log?.lesson?.title}
                        </h2>
                        {/* <span className={`px-2 py-1 text-xs font-medium bg-opacity-10 rounded-full ${
                          log.isCompleted ? "bg-brand-500 text-brand-500" : "bg-yellow-500 text-yellow-500"
                        }`}>
                          {log.isCompleted ? "Completed" : "In Progress"}
                        </span> */}
                      </div>
                      
                      <div className="space-y-1">
                        <p className="text-sm text-gray-600 flex items-center">
                          <span className="w-32 text-gray-500">Course:</span>
                          {log?.studymaterial?.lesson?.subject?.course?.name}
                        </p>
                        <p className="text-sm text-gray-600 flex items-center">
                          <span className="w-32 text-gray-500">Topic:</span>
                          {log?.studymaterial?.name}
                        </p>
                        {log.isCompleted && (
                          <p className="text-sm text-gray-600 flex items-center">
                            <span className="w-32 text-gray-500">Completed On:</span>
                            {new Date(log.completedDate).toLocaleDateString()}
                          </p>
                        )}
                      </div>
                    </div>

                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StaffLessonLogs;