
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const getLogs = createAsyncThunk("log/get", async (query) => {
  console.log(query)

  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
);
let params = new URLSearchParams(filteredQuery);

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/log?${params.toString()}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const addLog = createAsyncThunk("log/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/log/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});



export const updateLog = createAsyncThunk("log/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/log/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});


export const deleteLog = createAsyncThunk("log/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/log/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const logsSlice = createSlice({
  name: 'loges',
  initialState: {
  
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        rows: []
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLogs.fulfilled, (log, action) => {
     
        log.value.data.rows = action.payload.rows
        log.value.data.count = action.payload.count
        log.value.status = action.payload.message
        console.log('Updated log:', log.value.data.rows);

        
      })
      .addCase(getLogs.pending, (log) => {
        log.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getLogs.rejected, (log) => {
        log.status = "Failed to  fetch data..."
      })
      .addCase(updateLog.fulfilled, (log, action) => {
        console.log(action.payload.message, "action.payload.message")
        log.value.status = action.payload.message
        // find the index of the log with the updated id
        // const index = log.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the log at the found index with the updated log
        //   log.value.data[index] = action.payload;
        // }
      })
      .addCase(updateLog.pending, (log) => {
        log.status = "Updating log. Please wait a moment...";
      })
      .addCase(updateLog.rejected, (log) => {
        log.status = "Failed to update log...";
      })
      .addCase(deleteLog.fulfilled, (log, action) => {
        // // filter out the log with the deleted id
        // log.value.data = log.value.data.filter(item => item.id !== action.payload.id);
        log.value.status = action.payload.message
      })
      .addCase(deleteLog.pending, (log) => {
        log.status = "Deleting log. Please wait a moment...";
      })
      .addCase(deleteLog.rejected, (log) => {
        log.status = "Failed to delete log...";
      })
      .addCase(addLog.fulfilled, (log, action) => {
        // log.value.data.push(action.payload); // add the new log to the list
     })
     .addCase(addLog.pending, (log) => {
        log.status = "Adding log. Please wait a moment...";
     })
     .addCase(addLog.rejected, (log) => {
        log.status = "Failed to add log...";
     });
  }
})

export default logsSlice.reducer
