import { motion } from "framer-motion";
import InputField from "components/fields/InputField";
import toast, { Toaster } from "react-hot-toast";
import { useState } from "react";
import Eye from "components/icons/Eye";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../redux/actions/auth";
import { useNavigate } from "react-router-dom";

const containerVariants = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
};

export default function PasswordReset() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPasswords, setShowPasswords] = useState({
    current: false,
    new: false,
    confirm: false,
  });

  const validateInput = () => {
    if (!currentPassword) return "Current password is required";
    if (!newPassword) return "New password is required";
    if (newPassword.length < 8) return "New password must be at least 8 characters long";
    if (newPassword !== confirmPassword) return "Passwords do not match";
    return null;
  };

  const handlePasswordReset = async () => {
    const error = validateInput();
    if (error) {
      toast.error(error);
      return;
    }

    try {
      const resetAction = await dispatch(
        resetPassword({ currentPassword, newPassword })
      );

      if (resetAction.payload.success) {
          toast.success("Password reset successful");
          navigate("/parent/profile");
      } else {
        toast.error(resetAction.payload.message || "Password reset failed");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred during password reset");
    }
  };

  return (
    <div className="mb-5 flex flex-col items-center justify-center p-5 lg:flex-row lg:justify-start shadow-lg rounded-lg bg-white m-3">
      <div className="w-full max-w-full flex flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <h4 className="mb-2.5 text-4xl font-bold text-center text-navy-700 dark:text-white">
            Reset Password
          </h4>
          <p className="mb-5 ml-1 text-base text-gray-600 text-center">
            Enter your current password and set a new password to reset it!
          </p>

          <div className="relative">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Current Password*"
              placeholder="Enter your current password"
              id="currentPassword"
              type={showPasswords.current ? "text" : "password"}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <button
              type="button"
              className="absolute right-3 top-[50%] translate-y-[30%] text-gray-600"
              onClick={() =>
                setShowPasswords({ ...showPasswords, current: !showPasswords.current })
              }
            >
              <Eye closed={!showPasswords.current} />
            </button>
          </div>

          <div className="relative">
            <InputField
              variant="auth"
              extra="mb-3"
              label="New Password*"
              placeholder="Enter your new password"
              id="newPassword"
              type={showPasswords.new ? "text" : "password"}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <button
              type="button"
              className="absolute right-3 top-[50%] translate-y-[30%] text-gray-600"
              onClick={() =>
                setShowPasswords({ ...showPasswords, new: !showPasswords.new })
              }
            >
              <Eye closed={!showPasswords.new} />
            </button>
          </div>

          <div className="relative">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Confirm Password*"
              placeholder="Re-enter your new password"
              id="confirmPassword"
              type={showPasswords.confirm ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <button
              type="button"
              className="absolute right-3 top-[50%] translate-y-[30%] text-gray-600"
              onClick={() =>
                setShowPasswords({ ...showPasswords, confirm: !showPasswords.confirm })
              }
            >
              <Eye closed={!showPasswords.confirm} />
            </button>
          </div>

          <button
            onClick={handlePasswordReset}
            className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Reset Password
          </button>
        </motion.div>
        <Toaster />
      </div>
    </div>
  );
}
