import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ChevronDown, ChevronRight, Book, FileText, Link, Clock } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { getCourseSubjects } from '../../redux/actions/home';
import { useParams } from 'react-router-dom';

const SubjectTree = () => {
    const [subjects, setSubjects] = useState([]);
    const [course, setCourse] = useState({});
    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        dispatch(getCourseSubjects(id)).then((data) => {
            setSubjects(data.payload.subjects);
            setCourse(data.payload.course);
        });
    }, [dispatch]);

    const [expandedSubjects, setExpandedSubjects] = useState(new Set());
    const [expandedLessons, setExpandedLessons] = useState(new Set());

    const toggleSubject = (subjectId) => {
        setExpandedSubjects(prev => {
            const newSet = new Set(prev);
            if (newSet.has(subjectId)) {
                newSet.delete(subjectId);
            } else {
                newSet.add(subjectId);
            }
            return newSet;
        });
    };

    const toggleLesson = (lessonId) => {
        setExpandedLessons(prev => {
            const newSet = new Set(prev);
            if (newSet.has(lessonId)) {
                newSet.delete(lessonId);
            } else {
                newSet.add(lessonId);
            }
            return newSet;
        });
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        }).format(date);
    };

    if (!subjects) {
        return (
            <div className="bg-lightPrimary min-h-screen p-4">
                <div className="bg-white rounded-xl p-4 shadow-lg">
                    <div className="flex items-center justify-center space-x-2">
                        <div className="w-2 h-2 rounded-full animate-bounce bg-brand-500 delay-75"></div>
                        <div className="w-2 h-2 rounded-full animate-bounce bg-brand-500 delay-150"></div>
                        <div className="w-2 h-2 rounded-full animate-bounce bg-brand-500 delay-300"></div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="bg-lightPrimary min-h-screen p-4">
            <div className="bg-white rounded-xl p-4 shadow-lg transform transition-all duration-300 hover:shadow-xl">
                <h4 className='text-center font-bold text-xl text-brand-500 mb-3 mt-1'> {course.name} </h4>
                {subjects.length === 0 ? (
                    <p className="text-center mt-5 animate-fade-in">No subjects found</p>
                ) : (
                    subjects.map((subject) => (
                        <div key={subject.id} className={`mb-6 transform transition-all duration-300 hover:translate-x-1  rounded-b-lg ${expandedSubjects.has(subject.id) ? "shadow-lg" : ""}`}>
                            {/* Subject Level */}
                            <div
                                onClick={() => toggleSubject(subject.id)}
                                className="flex items-center p-4 bg-gray-100 rounded-t-lg cursor-pointer hover:bg-gray-100 transition-all duration-300 ease-in-out shadow-sm hover:shadow-md"
                            >
                                <div className="transform transition-transform duration-300 ease-in-out">
                                    {expandedSubjects.has(subject.id) ? (
                                        <ChevronDown className="text-brand-500 mr-2 transform rotate-0" />
                                    ) : (
                                        <ChevronRight className="text-brand-500 mr-2 transform rotate-0" />
                                    )}
                                </div>
                                {/* <Book className="text-brand-500 mr-3 transform transition-transform duration-300 hover:scale-110" /> */}
                                <span className="font-medium text-lg">{subject.name}</span>
                                {(subject.lessons?.length ?? 0) > 0 && (
                                    <span className="ml-2 text-sm text-gray-500">
                                        ({subject.lessons.length} {subject.lessons.length === 1 ? 'lesson' : 'lessons'})
                                    </span>
                                )}
                            </div>

                            {/* Lessons Level */}
                            <div className={`transition-all duration-300 ease-in-out overflow-hidden   ${expandedSubjects.has(subject.id) ? 'max-h-[2000px] opacity-100' : 'max-h-0 opacity-0'}`}>
                                <div className="ml-3 space-y-3 rounded-b-lg">
                                    {!subject.lessons?.length ? (
                                        <p className="text-gray-500 text-sm p-2">No lessons available</p>
                                    ) : (
                                        subject.lessons.map((lesson) => (
                                            <div key={lesson.id} className="transform transition-all duration-300 hover:translate-x-1 ">
                                                <div
                                                    onClick={() => toggleLesson(lesson.id)}
                                                    className="flex items-center p-3 rounded-md cursor-pointer  transition-all duration-300 ease-in-out"
                                                >
                                                    <div className="transform transition-transform duration-300 ease-in-out">
                                                        {expandedLessons.has(lesson.id) ? (
                                                            <ChevronDown className="text-gray-500 mr-2" />
                                                        ) : (
                                                            <ChevronRight className="text-gray-500 mr-2" />
                                                        )}
                                                    </div>
                                                    <FileText className="text-gray-500 mr-3 transform transition-transform duration-300 hover:scale-110" />
                                                    <div className="flex-1">
                                                        <span className="text-gray-700">{lesson.name}</span>
                                                        {lesson.completeWithin && lesson.frequency ? (
                                                            <div className="flex items-center text-xs text-gray-500 mt-2">
                                                                <Clock className="h-3 w-3 mr-1 animate-pulse" />
                                                                <span>Complete within {lesson.completeWithin} {lesson.frequency.toLowerCase()}</span>
                                                            </div>
                                                        ) : "" }
                                                    </div>
                                                </div>

                                                {/* Study Materials Level */}
                                                <div className={`transition-all duration-300 ease-in-out overflow-hidden ${expandedLessons.has(lesson.id) ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
                                                    <div className="ml-8 mt-2 space-y-2">
                                                        {(lesson.studymaterials?.length ?? 0) === 0 ? (
                                                            <p className="text-gray-500 text-sm p-2">No study materials available</p>
                                                        ) : (
                                                            lesson.studymaterials?.map((material) => (
                                                                <div
                                                                    key={material.id}
                                                                    className="p-3 rounded-md hover:bg-gray-50 transition-all duration-300 transform hover:translate-x-1"
                                                                >
                                                                    <div className="flex items-center">
                                                                        <Link className="h-4 w-4 text-gray-400 mr-2 transform transition-transform duration-300 hover:scale-110" />
                                                                        <span className="text-gray-600 font-medium">
                                                                            {material.name}
                                                                            &nbsp;
                                                                            &nbsp;
                                                                            &nbsp;
                                                                            {
                                                                                material?.lessonlogs?.length ?
                                                                                <>
                                                                                    <span className={`text-xs p-1 rounded-xl text-white ${material?.lessonlogs?.length ? "bg-brand-500" : "bg-red-500"} `}>
                                                                                        Completed
                                                                                    </span>
                                                                                    <br />
                                                                                    <span className='text-xs my-1'>{formatDate(material.lessonlogs[0].completedDate)} - {material?.lessonlogs[0]?.user?.fullname}</span>
                                                                                </> : ""
                                                                            }

                                                                        </span>
                                                                    </div>
                                                                    {material.desc && (
                                                                        <p className="text-sm text-gray-500 mt-2 ml-6">
                                                                            {material.desc}
                                                                        </p>
                                                                    )}
                                                                    {material.link && (
                                                                        <a
                                                                            href={material.link}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            className="text-sm text-brand-500 hover:underline ml-6 mt-2 block transition-all duration-300 hover:translate-x-1"
                                                                        >
                                                                            View Material
                                                                        </a>
                                                                    )}
                                                                </div>
                                                            ))
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default SubjectTree;