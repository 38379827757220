import { React, useEffect, useState } from 'react';
import { Routes, Route, Link, useLocation, useNavigate } from "react-router-dom";
import { validateToken, getProfile } from '../../redux/actions/auth';
import { setActiveStudentRedux } from '../../redux/actions/auth';
import { fetchSettings } from '../../redux/actions/admin/setting';
import { updateFCMtoken } from "../../redux/actions/auth";

import { useDispatch, useSelector } from 'react-redux'
import Home from '../../views/parent-staff/home';
import Enrollments from '../../views/parent-staff/enrollments';
import Notifications from '../../views/parent-staff/notifications';
import Course from '../../views/parent-staff/course';
import Classes from '../../views/parent-staff/classes';
import Profile from "../../views/parent-staff/profile";
import ResetPassword from "../../views/parent-staff/reset-password";
import DialogCustom from '../../components/dialog';
import toast, { Toaster } from 'react-hot-toast';
import Task from 'views/parent-staff/task';
import {
  FaHome,
  FaClipboardList,
  FaRegUser,
  FaBell,
  FaBook
} from "react-icons/fa";
import { GoTasklist } from "react-icons/go";

import brandImg from "assets/img/logo.png";
import CrudAttendances from 'views/parent-staff/crud-attendance';
import LessonLogs from 'views/parent-staff/lesson-log';
import CrudLessonLogs from 'views/parent-staff/crud-lesson-log';
import { NotebookTabs } from 'lucide-react';

const notifySuccess = (message) => toast.success(message, {
  style: {
    padding: '35px',
    color: '#a0ca00',
  },
  duration: 2000,
  iconTheme: {
    primary: '#a0ca00',
    secondary: '#222c25',
  }
})
const notifyError = (message) => toast.error(message, {
  style: {
    border: '1px solid #fff',
    padding: '35px',
    color: 'red',
  },
  iconTheme: {
    primary: 'red',
    secondary: '#fff',
  }
})




const NavbarItem = ({ to, children, active }) => (
  <Link
    to={to}
    className={`p-5 m-1 mb-0 text-brand-500 text-xl flex justify-center text-center  transition-all duration-150 ease-in-out ${active ? ' shadow-gray-400   shadow-xl translate-y-[-35%] bg-brand-500 !text-white rounded-full' : ''
      }`}
  >
    {children}
  </Link>
);


const App = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
    const [fcmToken, setFcmToken] = useState("")
  
  // const [fcmToken, setFcmToken] = useState("");
  const [businessLogo, setBusinessLogo] = useState({});

  const postMessage = (message) => {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify(message))
      }
    }
  
    
    const onLoggedIn = async () => {
      postMessage({ isLoggedIn: true })
    }
    
    const fetchToken = async () => {
      postMessage({ fetchToken: true })
    }

    const onLoggedOut = async () => {
      postMessage({ isLoggedOut: true })
    }

    // useEffect(async () => {
    //   onLoggedIn()
    //   if(window.ReactNativeWebView) {
    //     try {
    //       const token = await fetchToken()
    //       setFcmToken(JSON.stringify(token))
    //       } catch(err) {
    //         alert(err)
    //       }
    //   }
    //   // We expose a method to register a Device
    //   // window.registerDevice = async (token) => {
    //   //   alert(`registerDevice ${token}`)
    //   //   setFcmToken(token)
    //   // }
    // }, [])

    // useEffect(() => {
    //   onLoggedIn();
    
    //   // Listen for messages from React Native WebView
    //   window.addEventListener('message', (event) => {
    //     alert(`Received message from React Native WebView ${JSON.stringify(event.data)}`);
    //     try {
    //       const data = event.data;
    //       if (data.token) {
    //         alert(`Token: ${data.token}`);
    //         setFcmToken(data.token);
    //       }
    //     } catch (err) {
    //       alert(`Failed to parse token ${err}`);
    //     }
    //   });
    
    //   return () => {
    //     window.removeEventListener('message');
    //   };
    // }, []);

    useEffect(() => {
      fetchToken()
      const handleMessage = (event) => {
        if (event.data && event.data.type === 'token') {
          // alert(`Received from RN: ${event.data.token}`);
          // Handle the data as needed
          // alert(`registerDevice ${event.data.payload}`)
          dispatch( updateFCMtoken({fcmToken: event.data.token}) )
          // setFcmToken(event.data.token);
        }
      };
    
      window.addEventListener('message', handleMessage);
    
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }, []);

    // useEffect(() => {
    //   // alert(`fcmToken ${fcmToken}`)
    //   dispatch( updateFCMtoken({fcmToken}) )
    // }, [fcmToken])

  useEffect(() => {
    dispatch(validateToken())
    window.scrollTo(0, 0);  // Scrolls to top-left corner of the window
    setHeader(location.pathname.split("/")[2].toUpperCase())
  }, [location.pathname]);

  useEffect(() => {
    async function fetchData() {
      const action = await dispatch(fetchSettings())
      // console.log("action --"+JSON.stringify(action.payload.profile));
      setBusinessLogo(action.payload.profile)
    }
    fetchData()

  }, []);


  // dispatch(getProfile());

  const [activeStudent, setActiveStudent] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [header, setHeader] = useState("Home");
  // if(!user.students) dispatch(getProfile());

  const user = useSelector((user) => user.auth.value.profile);


  useEffect(() => {
    async function fetchData() {
      dispatch(getProfile());
      setLoading(false);
      
    }
    fetchData()
  }, [dispatch]);


  useEffect(() => {
    console.log(user.rolename, "user.students ---------------------")
    if (user && user.students) {
      setActiveStudent(user.students[0]);
      dispatch(setActiveStudentRedux(user.students[0]));
    }
    if (user?.role?.name === "Staff") setIsStaff(true);
  }, [user]);

  if (loading) {
    // Show loading indicator while fetching user data
    return <div>Loading...</div>;
  }



  return (
    <div className="flex flex-col h-screen">
      <DialogCustom
        open={isDialogOpen}
        onOpenChange={setIsDialogOpen}
        // buttonTitle={
        //   <button className="text-black linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-brand-850/80 hover:text-white  active:!bg-white/70">
        //     Add Center
        //   </button>
        // }
        dialogTitle="Select Student"
        dialogWidth="w-11/12"
        dialogDesc="Select a student to view thier details"
        content={
          <>
            {
              user && user.students && user.students.map((student, index) => {
                return (
                  <div
                    onClick={() => {
                      setActiveStudent(student)
                      dispatch(setActiveStudentRedux(student));
                      setIsDialogOpen(false)
                      navigate("/parent/home")
                      notifySuccess(`Changed profile to ${student.admission.name}`)
                    }}
                    className={`mb-3 border-2 border-brand-500 flex justify-between p-3 rounded-xl shadow ${student.id == activeStudent.id ? "!bg-brand-500 text-white" : ""}`}>
                    <div className="profile">
                      <div className={`bg-brand-500 m-1 rounded-full  ${student.id == activeStudent.id ? "!bg-white" : ""} `}>
                        {student?.admission?.profile ? <img src={`${process.env.REACT_APP_URL + "images" + student.admission.profile}`} alt="" className='w-10 h-10 object-cover rounded-full' /> : <FaRegUser className={`!text-white ${student.id == activeStudent.id ? "!text-brand-500" : ""}`} />}
                      </div>
                    </div>
                    <div className="name w-9/12 flex flex-col items-start ml-2">
                      <p className='font-semibold text-sm'>{student.admission.name}</p>
                      <p className='text-xs'>{student.enrollments[0]?.course?.name + " - " + student.enrollments[0]?.class?.name}</p>
                    </div>
                  </div>
                )
              })
            }
          </>

        }
      />
      <div className="">
        <header className='m-3 rounded-xl shadow-xl p-3 bg-white flex justify-between items-center'>
          <img
            className='w-12 m-1 rounded-xl'
            // src={businessLogo||brandImg} 
            src={businessLogo ? `${process.env.REACT_APP_URL + "images" + businessLogo}` : brandImg}
            alt=""
            onClick={() => { navigate("/parent/home") }}
          />
          <h1 className='font-bold text-xl text-brand-500'> {header} </h1>
          <div className=' bg-brand-500 m-1 rounded-xl p-2' onClick={() => {
            setIsDialogOpen(true)
          }}>

            {/* <FaRegUser className='!text-white ' /> */}
            {activeStudent?.admission?.profile ? <img src={`${process.env.REACT_APP_URL + "images" + activeStudent.admission.profile}`} alt="" className='w-10 h-10 object-cover rounded-full' /> : <FaRegUser className='!text-white ' />}
          </div>
        </header>
        <div className=' rounded-t-xl pb-20'>
          <Routes key={location.key}>
            {/* Main Routes */}
            <Route path="/home" element={<Home user={user} student={activeStudent} />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/tasks" element={<Task user={user} student={activeStudent} />} />
            <Route path="/classes" element={<Classes classes={user.classes}  />} />

            {/* Sub Routes */}
            <Route path="/enrollments" element={<Enrollments />} />
            <Route path="/course/:id" element={<Course />} />
            <Route path="/attendance/:id" element={<CrudAttendances />} />

            <Route path="/lessons" element={<CrudLessonLogs />} />
            <Route path="/add-lesson-log" element={<CrudLessonLogs />} />
          </Routes>
        </div>
      </div>
      {/* <p> IsStaff - {isStaff ? "true" : "false"} </p> */}
      <div className="shadow-gray-400   shadow-2xl  fixed bg-white flex justify-around w-full" style={{ bottom: 0 }}>
        <NavbarItem styles="rounded-t-xl" to="/parent/home" active={location.pathname === '/parent/home'} name={"Home"}>
          <FaHome />
        </NavbarItem>
        {
          !isStaff &&
          <>
            <NavbarItem styles="rounded-t-xl" to="/parent/enrollments" active={location.pathname === '/parent/enrollments'} name={"Enrollments"}>
              <FaBook />
            </NavbarItem>
            <NavbarItem
              to="/parent/tasks"
              active={location.pathname === '/parent/tasks'}
            >
              <FaClipboardList />
            </NavbarItem>
          </>
        }
        {
          isStaff &&
          <>
            <NavbarItem 
              styles="rounded-t-xl" 
              to="/parent/classes" 
              active={location.pathname === '/parent/classes' || location.pathname.includes("attendance")} 
              name={"Classes"}
            >
              <GoTasklist />
            </NavbarItem>
            <NavbarItem 
              styles="rounded-t-xl" 
              to="/parent/lessons" 
              active={location.pathname === '/parent/lessons' || location.pathname.includes("lessons")} 
              name={"Lessons"}
            >
              <NotebookTabs />
            </NavbarItem>
          </>
        }
        {/* <NavbarItem
          to="/parent/notifications"
          active={location.pathname === '/parent/notifications'}
        >
          <FaBell />
        </NavbarItem> */}
        <NavbarItem
          to="/parent/profile"
          active={location.pathname === '/parent/profile'}
        >
          <FaRegUser />
        </NavbarItem>

      </div>
      <Toaster />
    </div>
  );
};

export default App;
