import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getTasks,
  addTask,
  updateTask,
  deleteTask,
} from "../../../../redux/actions/admin/task";
import { getClasses } from "../../../../redux/actions/admin/classRoom";
import { getCourses } from "../../../../redux/actions/admin/course";
import { getTaskTypes } from "../../../../redux/actions/admin/taskType";
import { getUsers } from "../../../../redux/actions/admin/user";
import toast, { Toaster } from "react-hot-toast";
import DropdownSelect from "components/select";
import Paginate from "components/paginate";
import { useNavigate } from "react-router-dom";
import { FiSearch, FiEdit2, FiTrash, FiEye } from "react-icons/fi";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/shadcn/table";

import DialogCustom from "../../../../components/dialog";
import Form from "../../../../components/form";

export const Task = (props) => {
  useEffect(() => {
    dispatch(getTasks({}));
    dispatch(getClasses({ limit: 50 }));
    dispatch(getCourses({ limit: 50 }));
    dispatch(getTaskTypes({}));
    dispatch(getUsers({status: "All",
    limit: 100}));
    // setFormData({})
  }, []);
  
  const [formData, setFormData] = useState({});
  const [filter, setFilter] = useState({
    task: "",
    status: "All",
    limit: 10,
    page: 1,
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogSelectOpen, setIsDialogSelectOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [fields, setFields] = useState([]);

  const notifySuccess = (message) =>
    toast.success(message, {
      style: {
        padding: "35px",
        color: "#a0ca00",
      },
      duration: 3000,
      iconTheme: {
        primary: "#a0ca00",
        secondary: "#222c25",
      },
    });
  const notifyError = (message) =>
    toast.error(message, {
      style: {
        border: "1px solid #fff",
        padding: "35px",
        color: "red",
      },
      iconTheme: {
        primary: "red",
        secondary: "#fff",
      },
    });

  const dispatch = useDispatch();
  const navigate = useNavigate();

 

  const tasks = useSelector((tasks) => tasks.task.value.data);
  const users = useSelector((users) => users.user.value.data);
  const classes = useSelector((classes) => classes.classRoom.value.data);
  const courses = useSelector((courses) => courses.course.value.data);
  const taskTypes = useSelector((taskTypes) => taskTypes.taskType.value.data);

  const handleActionButtonClick = (taskId, action) => {
    if (action === "edit") setIsDialogOpen(true);
    if (action === "delete") setIsDeleteDialogOpen(true);
    if (taskId == null) {
      // setIsDialogOpen(true)
      setIsDialogSelectOpen(true);
      return setFormData({});
    }

    const selectedTask = tasks.rows.find((task) => task.id === taskId);
    
    let task = Object.fromEntries(
      Object.entries(selectedTask).filter(([key, value]) => value != null && value !== '')
    );
// console.log("setFormData(task);"+JSON.stringify(task));
    setFields(fields)
    setFormData(task);
  };

  useEffect(() => {
    dispatch(getTasks(filter));
  }, [filter]);

  const handleCrudTask = async () => {
    try {
      setIsDialogOpen(false);
      console.log("before fields "+fields);
      setFields(initialFields);
      console.log("after fields "+fields);

      if (formData.id) await dispatch(updateTask(formData));
      // if (!formData.id) await dispatch(addTask(formData));
      if (!formData.id)
        // await dispatch(addTask({ ...formData, userId: user.id }));
        await dispatch(addTask({ ...formData }));
      notifySuccess("Success");

      setFormData({});
      await dispatch(getTasks({}));
    } catch (error) {
      console.error("Failed to add Task:", error);
    }
  };

  const handleDelete = async () => {
    try {
      setIsDeleteDialogOpen(false);
      await dispatch(deleteTask(formData.id));
      // setFormData({});
      notifySuccess("Delete task successfully");
      await dispatch(getTasks({}));
    } catch (error) {
      console.error("Failed to add Task:", error);
    }
  };
  
  
  useEffect(() => {
    setFields(initialFields);
  }, [tasks, users, classes, taskTypes]);
  
  

  const initialFields = [
    {
            id: "task",
            name: "task",
            label: "Task Name",
            placeholder: "Enter Task's Name",
            type: "text",
          },
          {
            id: "classes",
            name: "classes",
            label: "Class",
            placeHolder: "Select Class",
            // type: "dropdown",
            type: "multiselect",
            // list: classes.rows,
            toSelect: ["id"],
            selectedvalue: "class",
            options: classes.rows ? classes.rows : [],
          },
          {
            id: "courses",
            name: "courses",
            label: "Course",
            // placeHolder: "Select Course",
            type: "multiselect",
            toSelect: ["id"],
            options: courses.rows ? courses.rows : [],
          },
          // {
          //   id: "userId",
          //   name: "userId",
          //   label: "User",
          //   placeHolder: "Select User",
          //   type: "dropdown",
          //   list: users.rows,
          //   toSelect: "id",
          //   selectedvalue: "user",
          //   toShow: ['fullname']
          // },
          {
            id: "classId",
            name: "classId",
            label: "Select Class",
            placeHolder: "Select Class",
            type: "dropdown",
            list: classes.rows,
            toSelect: "id",
            selectedvalue: "class",
          },
          {
            id: "courseId",
            name: "courseId",
            label: "Course",
            placeHolder: "Select Course",
            type: "dropdown",
            list: courses.rows,
            toSelect: "id",
            selectedvalue: "course",
          },
          {
            id: "tasktypeId",
            name: "taskTypeId",
            label: "Task Type",
            placeHolder: "Select TaskType",
            type: "dropdown",
            list: taskTypes.rows,
            toSelect: "id",
            selectedvalue: "tasktype",
          },
          {
            id: "startDate",
            name: "startDate",
            label: "Start Date",
            type: "datetime",
            isSingle: true,
            isRange: false,
          },
          {
            id: "endDate",
            name: "endDate",
            label: "End Date",
            type: "datetime",
            isSingle: true,
            isRange: false,
          },
  ];
  
  const handleSelectedTask = (toUnselect) => {
    setIsDialogSelectOpen(false);
    setIsDialogOpen(true);
    const updatedFields = [...initialFields]; 
    let filteredFields = updatedFields

    toUnselect.map((toRemove) => {
      console.log("removing field", toRemove);
      filteredFields = filteredFields.filter((field) => field.name !== toRemove);
    });
    // filteredFields = updatedFields.filter((field) => field.name !== toUnselect[0]);
    // filteredFields = updatedFields.filter((field) => field.name !== toUnselect[1]);

    

    console.log("filteredFields ...............", filteredFields);
    setFields(filteredFields);
  };
  
  return (
    <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4 dark:bg-navy-700">
      <Toaster />
      <div className="h-full w-full rounded-xl">
        {/* <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
                    <Widget
                        icon={<FiLayers className="h-7 w-7" />}
                        subtitle={tasks.count}
                        styling={
                            {
                                iconContStyles: "bg-brand-500",
                                iconStyles: "text-white",
                                border: "border-solid border-2 border-yellow-100"
                            }
                        }
                    />
                    <Widget
                        icon={<FiTrendingUp className="h-7 w-7" />}
                        subtitle={tasks.activeCount}
                        styling={
                            {
                                iconContStyles: "bg-green-500",
                                iconStyles: "text-white",
                                border: "border-solid border-2 border-green-100"
                            }
                        }
                    />
                    <Widget
                        icon={<FiX className="h-7 w-7" />}
                        subtitle={tasks.inactiveCount}
                        styling={
                            {
                                iconContStyles: "bg-red-500",
                                iconStyles: "text-white",
                                border: "border-solid border-2 border-red-100"
                            }
                        }
                    />


                </div> */}

        <div className="mt-3  rounded-xl border-2 border-solid border-gray-100 p-4">
          <Table className="border-grey-500 border-b">
            <TableCaption className="text-black mb-3  text-start text-2xl font-bold">
              <div className="flex items-center justify-between">
                <div className="flex w-1/2 items-center justify-between">
                  <div className="mr-5 flex h-full items-center rounded-full bg-lightPrimary p-4 text-navy-700 dark:bg-navy-900 dark:text-white ">
                    <p className="pl-3 pr-2 text-xl">
                      <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                    </p>
                    <input
                      // value={filter.name}
                      onChange={(e) => {
                        setFilter({ ...filter, ["task"]: e.target.value });
                      }}
                      type="text"
                      placeholder="Search..."
                      className="block h-full w-full  bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                    />
                  </div>
                  {/* <DropdownSelect
                                                list={[
                                                    { name: "All", id: 1 },
                                                    { name: "Active", id: 2 },
                                                    { name: "Inactive", id: 3 },
                                                ]}
                                                triggerStyles="bg-lightPrimary !rounded-full"
                                                // listStyles="text-sm text-red-700"
                                                placeHolder={"Status"}
                                                toSelect={"name"}
                                                onChange={(value) => {
                                                    setFilter({ ...filter, ["status"]: value })
                                                }}
                                            /> */}
                </div>
                <div className="flex  justify-end">
                  <button
                    onClick={() => handleActionButtonClick(null, "add")}
                    className="linear rounded-full border-2 bg-brand-700 px-4 py-3 text-center text-base font-medium text-white transition duration-200 hover:border-2 hover:border-brand-500 hover:!bg-white hover:text-brand-500  active:!bg-white/70"
                  >
                    Add Task
                  </button>
                </div>
              </div>
            </TableCaption>
            {tasks.rows.length > 0 && (
              <div className="">
                <TableHeader>
                  <TableRow className="dark:text-white">
                    <TableHead className="w-2/4 "> Task </TableHead>
                    <TableHead className="w-11/12 "> Assigned To </TableHead>
                    <TableHead className="w-1/3 text-center ">
                      Actions
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {tasks.rows.map((task) => (
                    <TableRow key={task.id}>
                      <TableCell className="font-medium">
                        <h4 className="text-brand-500 cursor-pointer"> {task.task} </h4>
                      </TableCell>
                      <TableCell className="font-medium">
                        <h4 className=""> {task.courseId ? "Course - " + task?.course?.name : "Class - " + task?.class?.name} </h4>
                      </TableCell>
                      <TableCell className="w-1/6  ">
                        <div className="flex w-full justify-around">
                          <div
                            onClick={() =>
                              navigate(`/admin/task/logs?taskId=${task.id}`)
                            }
                            title="View Tasks"
                            className="mx-2 w-fit cursor-pointer rounded-3xl bg-brand-500 p-1"
                          >
                            <FiEye className="h-4 w-4 text-white" />
                          </div>
                          <div
                            onClick={() =>{
                              handleActionButtonClick(task.id, "edit")
                              if(task.classId) {
                                handleSelectedTask(["courses", "courseId", "classes"])
                              } else {
                                handleSelectedTask(["classes", "classId", "courses"])
                              }
                            }}
                            className="mx-2 w-fit cursor-pointer rounded-3xl bg-yellow-500 p-1"
                          >
                            <FiEdit2 className="h-4 w-4 text-white" />
                          </div>
                          
                          <div
                            onClick={() =>
                              handleActionButtonClick(task.id, "delete")
                            }
                            className="mx-2 w-fit cursor-pointer rounded-3xl bg-red-500 p-1"
                          >
                            <FiTrash className="h-4 w-4 text-white" />
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <Paginate
                  page={filter.page}
                  totalCount={tasks.count}
                  limit={filter.limit}
                  changePage={(value) => {
                    setFilter({ ...filter, ["page"]: value });
                  }}
                />
              </div>
            )}
          </Table>
          {tasks.rows.length == 0 && (
            <div className="flex flex-col items-center justify-center p-10">
              <p className="mb-5 font-semibold"> No Tasks Found! </p>
              <button
                onClick={() => handleActionButtonClick(null, "add")}
                className="linear rounded-xl border-2 bg-brand-700 px-4 py-2 text-center text-base font-medium text-white transition duration-200 hover:border-2 hover:border-brand-500 hover:!bg-white hover:text-brand-500  active:!bg-white/70"
              >
                Add Task
              </button>
            </div>
          )}

          <DialogCustom
            open={isDialogSelectOpen}
            onOpenChange={setIsDialogSelectOpen}
            // dialogTitle="Edit Task"
            dialogWidth="w-1/2"
            // dialogDesc="Update the neccessary fields and save."
            content={
              <>                
                <div className="flex flex-col items-center justify-center space-y-5">
                  <h1 className="text-2xl font-black tracking-wide">
                    Create Task For Course/Class Please Select Any one
                  </h1>
                  <button
                    className="text-black linear h-10 w-24 rounded-xl bg-brand-700 text-center text-base font-medium transition duration-200 hover:!bg-brand-850/80 hover:text-white active:!bg-white/70"
                    onClick={() => handleSelectedTask(["classes", "classId", "courseId"])}
                  >
                    Course
                  </button>
                  <p>or</p>
                  <button
                    className="text-black linear h-10 w-24 rounded-xl bg-brand-700 text-center text-base font-medium transition duration-200 hover:!bg-brand-850/80 hover:text-white active:!bg-white/70"
                    onClick={() =>  handleSelectedTask(["courses", "courseId", "classId"])}
                  >
                    Class
                  </button>
                </div>
              </>
            }
          />

          <DialogCustom
            open={isDialogOpen}
            onOpenChange={setIsDialogOpen}
            dialogTitle={formData.id ? "Edit Task" : "Add" + " Task"}
            dialogWidth="w-1/2"
            dialogDesc={(formData.id ? "Update" : "Add") + " the necessary fields and save."}
            content={
              <>
              {/* {console.log("DialogCustom fields"+JSON.stringify(fields))} */}
                <Form
                  fields={fields}
                  formData={formData}
                  onChange={(newFormData) => setFormData(newFormData)}
                />
                <div className="flex justify-end">
                  <button
                    onClick={handleCrudTask}
                    className="text-black linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-brand-850/80 hover:text-white  active:!bg-white/70"
                  >
                    {formData.id ? "Update" : "Add" + " Task"}
                  </button>
                </div>
              </>
            }
          />

          <DialogCustom
            open={isDeleteDialogOpen}
            onOpenChange={setIsDeleteDialogOpen}
            dialogTitle="Delete Task"
            dialogWidth="w-1/2"
            dialogDesc="Are you sure you want to delete this task ?"
            content={
              <div className="flex flex-row-reverse">
                <button
                  onClick={handleDelete}
                  className=" linear rounded-xl border-2 border-white bg-red-700 px-4 py-2 text-center text-base font-medium text-white transition duration-200 hover:border-2 hover:border-red-700 hover:!bg-white hover:text-red-500 hover:shadow-lg  "
                >
                  Delete
                </button>
                <button
                  onClick={() => {
                    setIsDeleteDialogOpen(false);
                  }}
                  className="linear mr-4 rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium text-white transition duration-200 hover:border-2 hover:border-brand-700 hover:!bg-white hover:text-brand-500 hover:shadow-lg  "
                >
                  Cancel
                </button>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Task;
