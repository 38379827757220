import React, { useState, useEffect } from 'react';
import { getFees, updateFeesLogs } from '../../../redux/actions/admin/fees';
import { useSelector, useDispatch } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '../../../components/shadcn/table';
import { FiSearch } from 'react-icons/fi';
import moment from 'moment';

export const SingleFee = (props) => {
    const { setTitle } = props;
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const fees = useSelector((state) => state.fees.value.data);
    const [studentFees, setStudentFees] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        dispatch(getFees({ id }));
    }, [dispatch, id]);

    useEffect(() => {
        if (fees && fees.rows && fees.rows.length > 0) {
            const parentFee = fees.rows[0]; // Take parent fees record
            setTitle(`Fees - ${parentFee.feesmaster?.name || 'Details'}`);

            if (parentFee.feeslogs) {
                const initialFees = parentFee.feeslogs.map((log) => ({
                    id: log.id,
                    studentId: log.enrollment?.studentId || '',
                    studentName: log.enrollment?.student?.admission?.name || 'N/A',
                    amount: parentFee?.feesmaster?.amount,
                    paid: log.paid || false,
                    amountPaid: log.amountPaid || 0,
                    period: parentFee.period,
                }));
                setStudentFees(initialFees);
            }
        }
    }, [fees]);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleTogglePayment = (feeId) => {
        setStudentFees((prev) =>
            prev.map((fee) =>
                fee.id === feeId ? { ...fee, paid: !fee.paid } : fee
            )
        );
    };

    const handleMarkAllPaid = () => {
        const allPaid = studentFees.every((fee) => fee.paid);
        setStudentFees((prev) =>
            prev.map((fee) => ({ ...fee, paid: !allPaid }))
        );
    };

    const handleUpdateFees = async () => {
        try {
            const updatedFees = studentFees.map((fee) => ({
                id: fee.id,
                paid: fee.paid,
                amountPaid: fee.paid ? fee?.amount : 0, // Mark as fully paid
            }));
            await dispatch(updateFeesLogs({ fees: updatedFees }));
            toast.success('Fees updated successfully');
            dispatch(getFees({ id })); // Refresh the data
        } catch (error) {
            console.error('Update error:', error);
            toast.error('Failed to update fees');
        }
    };

    const filteredFees = studentFees.filter((fee) =>
        fee.studentName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4 dark:bg-navy-700">
            <Toaster />
            <div className="h-full w-full rounded-xl">
                <div className="mb-6 p-4 rounded-xl border-2 border-gray-100">
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                        <div className="p-4 rounded-lg bg-gray-50">
                            <h3 className="text-sm text-gray-500 mb-1">Amount</h3>
                            <p className="font-medium text-gray-900">
                                Rs. {fees?.rows?.[0]?.feesmaster?.amount || 'N/A'}
                            </p>
                        </div>
                        <div className="p-4 rounded-lg bg-gray-50">
                            <h3 className="text-sm text-gray-500 mb-1">Period</h3>
                            <p className="font-medium text-gray-900">
                                {fees?.rows?.[0]?.period || 'N/A'}
                            </p>
                        </div>
                        <div className="p-4 rounded-lg bg-green-50">
                            <h3 className="text-sm text-green-600 mb-1">Total Paid</h3>
                            <p className="font-medium text-green-700">
                                {studentFees.filter((fee) => fee.paid).length}
                            </p>
                        </div>
                        <div className="p-4 rounded-lg bg-red-50">
                            <h3 className="text-sm text-red-600 mb-1">Total Unpaid</h3>
                            <p className="font-medium text-red-700">
                                {studentFees.filter((fee) => !fee.paid).length}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mt-3 p-4 rounded-xl border-solid border-2 border-gray-100">
                    <Table className="border-b border-grey-500">
                        <TableCaption>
                            <div className="flex justify-between items-center">
                                <div className="flex items-center">
                                    <FiSearch className="h-4 w-4 text-gray-400" />
                                    <input
                                        value={searchQuery}
                                        onChange={handleSearch}
                                        type="text"
                                        placeholder="Search..."
                                        className="ml-2 block w-full bg-gray-50 text-sm text-gray-700 placeholder-gray-400"
                                    />
                                </div>
                                <div className="flex">
                                    {/* <button
                                        onClick={handleMarkAllPaid}
                                        className="px-4 py-2 rounded-lg bg-blue-500 text-white font-medium"
                                    >
                                        Mark All {studentFees.every((fee) => fee.paid) ? 'Unpaid' : 'Paid'}
                                    </button> */}
                                    <button
                                        onClick={handleUpdateFees}
                                        className="ml-4 px-4 py-2 rounded-lg bg-green-500 text-white font-medium"
                                    >
                                        Update Fees
                                    </button>
                                </div>
                            </div>
                        </TableCaption>
                        <TableHeader>
                            <TableRow>
                                <TableHead>Student Name</TableHead>
                                {/* <TableHead>Amount</TableHead> */}
                                {/* <TableHead>Period</TableHead> */}
                                <TableHead>Status</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {filteredFees.map((fee) => (
                                <TableRow key={fee.id}>
                                    <TableCell>{fee.studentName}</TableCell>
                                    {/* <TableCell>Rs. {fee.amount}</TableCell> */}
                                    {/* <TableCell>{fee.period}</TableCell> */}
                                    <TableCell>
                                        <button
                                            onClick={() => handleTogglePayment(fee.id)}
                                            className={`px-4 py-2 rounded-lg ${fee.paid ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
                                                }`}
                                        >
                                            {fee.paid ? 'Paid' : 'Unpaid'}
                                        </button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {filteredFees.length === 0 && (
                        <div className="p-10 flex flex-col justify-center items-center">
                            <p className="mb-5 font-semibold">No Fees Records Found!</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SingleFee;
