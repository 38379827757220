import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';
import axios from 'axios';


export const getCourses = createAsyncThunk("course/get", async (query) => {
  console.log(query)

  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
);
let params = new URLSearchParams(filteredQuery);

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/course?${params.toString()}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const getCourseStudents = createAsyncThunk("course/getStudents", async (courseId) => {
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/course/${courseId}/enrollments`, {
    headers: createHeaders()
  });
  let json = await response.json();
  return json;
});

export const addCourse = createAsyncThunk("course/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/course/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});



export const updateCourse = createAsyncThunk("course/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/course/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});


export const deleteCourse = createAsyncThunk("course/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/course/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const importStudents = ({ courseId, students }) => {
    return async (dispatch) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_URL}admin/api/course/${courseId}/import-students`,
                {
                    method: 'POST',
                    headers: createHeaders(),
                    body: JSON.stringify({ students })
                }
            );
            
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || 'Failed to import students');
            }
            return data;
        } catch (error) {
            throw error.message || 'Failed to import students';
        }
    };
};

export const uploadContent = ({ courseId, content }) => {
  // return console.log(courseId, content)
    return async (dispatch) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_URL}admin/api/course/${courseId}/upload-content`,
                {
                    method: 'POST',
                    headers: createHeaders(),
                    body: JSON.stringify({ content })
                }
            );
            
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || 'Failed to import students');
            }
            return data;
        } catch (error) {
            throw error.message || 'Failed to import students';
        }
    };
};

export const courseSlice = createSlice({
  name: 'course',
  initialState: {
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        rows: []
      },
      students: {
        data: []  // Empty by default
      }
    }
  },
  reducers: {
    resetStudents: (state) => {
      state.value.students = {
        data: []
      };
    },
    clearStudents: (state) => {
      state.value.students = {
        data: []
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCourses.fulfilled, (course, action) => {
        console.log()
     
        course.value.data.rows = action.payload.rows
        course.value.data.count = action.payload.count
        course.value.status = action.payload.message
        console.log('Updated course:', course.value.data.rows);

        
      })
      .addCase(getCourseStudents.fulfilled, (state, action) => {
        state.value.students = {
          data: action.payload.data
        };
      })
      .addCase(getCourseStudents.pending, (state) => {
        state.value.status = "Fetching students...";
      })
      .addCase(getCourseStudents.rejected, (state) => {
        state.value.status = "Failed to fetch students...";
        // Also reset students on error
        state.value.students = {
          data: []
        };
      })
      .addCase(getCourses.pending, (course) => {
        course.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getCourses.rejected, (course) => {
        course.status = "Failed to  fetch data..."
      })
      .addCase(updateCourse.fulfilled, (course, action) => {
        console.log(action.payload.message, "action.payload.message")
        course.value.status = action.payload.message
        // find the index of the course with the updated id
        // const index = course.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the course at the found index with the updated course
        //   course.value.data[index] = action.payload;
        // }
      })
      .addCase(updateCourse.pending, (course) => {
        course.status = "Updating course. Please wait a moment...";
      })
      .addCase(updateCourse.rejected, (course) => {
        course.status = "Failed to update course...";
      })
      .addCase(deleteCourse.fulfilled, (course, action) => {
        // // filter out the course with the deleted id
        // course.value.data = course.value.data.filter(item => item.id !== action.payload.id);
        course.value.status = action.payload.message
      })
      .addCase(deleteCourse.pending, (course) => {
        course.status = "Deleting course. Please wait a moment...";
      })
      .addCase(deleteCourse.rejected, (course) => {
        course.status = "Failed to delete course...";
      })
      .addCase(addCourse.fulfilled, (course, action) => {
        // course.value.data.push(action.payload); // add the new course to the list
     })
     .addCase(addCourse.pending, (course) => {
        course.status = "Adding course. Please wait a moment...";
     })
     .addCase(addCourse.rejected, (course) => {
        course.status = "Failed to add course...";
     });
  }
})

// Export the action
export const { resetStudents, clearStudents } = courseSlice.actions;

export default courseSlice.reducer
